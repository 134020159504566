/**
 * @ngdoc Component
 * @name tallyfy.organizations.users.component.orgGuestUsers
 * @module tallyfy.organizations.users
 *
 * @description
 * A component to manage org guests
 *
 * @author Adi Winata (adheegm@gmail.com)
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations.users')
    .component('orgGuestUsers', {
      templateUrl: 'app/modules/users/guestUsers/guest-users.component.html',
      controller:
        /*@ngInject*/
        function (_, $q, $filter, $rootScope, $stateParams, UsersService, Growl, blockUI, AuthPlan, PLANS, $state, Helper, $uibModal, store,
          OrganizationsService, GuestTableService, $confirm, $log) {
          var $ctrl = this,
            allGuests,
            growl = new Growl(),
            unregisterUpdateOrganizationMembers,
            blockUI = blockUI.instances.get('guestUsers');

          /**
           * Component's lifeCycle hooks
          */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.guestPagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          $ctrl.getGuests = getGuests;

          $ctrl.initTableConfig = initTableConfig;
          $ctrl.initCadencesTableConfig = initCadencesTableConfig;

          $ctrl.createGuest = createGuest;
          $ctrl.setTabActive = setTabActive;

          /**
           * @function $onInit
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
          */
          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.currentUser = $rootScope.identity.id;
            $ctrl.orgID = _.get($rootScope.identity, 'default_organization.id');
            $ctrl.orgPlanCode = _.get($rootScope.identity, 'default_organization.subscription.data.plan_code');
            $ctrl.isFreePlan = $ctrl.orgPlanCode === PLANS.FREE && !(_.get($rootScope.identity, 'default_organization.in_trial'));
            $ctrl.activeTab = _.get($stateParams, 'tab', 'table') === 'cadences' ? 'cadences' : 'table';
            if ($state.params.success) {
              showGrowlMessage($filter('translate')($state.params.success));
            }
          }

          function onChanges() { }

          function onDestroy() {
            unregisterUpdateOrganizationMembers();
          }

          /**
           * @ngdoc method
           * @name viewTasks
           * 
           * @description redirect to view task with selected user
           * @param {Integer} userId
          */
          function viewTasks(e, uid) {
            if (AuthPlan.hasAnyAuthority(PLANS.FREE, 'myTask')) {
              var guest = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
              $state.go('home', {
                assignee: guest.email
              });
            }
          }

          function setTabActive(selected) {
            $ctrl.activeTab = selected;
            $state.transitionTo($state.current, angular.extend($stateParams, {
              tab: selected
            }), { notify: false });
          }

          /**
           * @ngdoc method
           * @name showGrowlMessage 
           * 
           * @description To display global growl message
           * @param {string} message
           */
          function showGrowlMessage(message) {
            Helper.clearUniversalAlert('global');
            growl.success(message, {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          function showEmailLogs(e, uid) {
            $uibModal.open({
              backdrop: 'static',
              component: 'securityAndLog',
              windowClass: 'email-logs',
              size: 'lg',
              resolve: {
                user: _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid })
              }
            });
          }

          function copyGuestLink(e, uid) {
            $uibModal.open({
              backdrop: 'static',
              component: 'guestCopyLink',
              windowClass: 'guest-copy-link-modal',
              size: 'md',
              resolve: {
                guest: function () {
                  return _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
                },
                org_id: function () {
                  return _.get($rootScope, 'identity.default_organization.id');
                }
              }
            });
          }

          function enableLink(e, uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            OrganizationsService.setGuestEnableLink(
              { guest_email: user.email }
            ).then(function (res) {
              var updatedGuest = _.find(allGuests, { email: _.get(res.data, 'email') });
              angular.extend(updatedGuest, res.data);
              $ctrl.tableOptions.gridConfig.dataSource.data(allGuests);
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              showGrowlMessage($filter('translate')('regularUsers.associatedMember.guestEnable'));
            });
          }

          function disableLink(e, uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            OrganizationsService.setGuestDisableLink(
              { guest_email: user.email }
            ).then(function (res) {
              var updatedGuest = _.find(allGuests, { email: _.get(res.data, 'email') });
              angular.extend(updatedGuest, res.data);
              $ctrl.tableOptions.gridConfig.dataSource.data(allGuests);
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              showGrowlMessage($filter('translate')('regularUsers.associatedMember.guestDisable'));
            });
          }

          function getGuests(args) {
            var defer = $q.defer();
            $ctrl.isLoading = true;
            var pagination = angular.extend({}, $ctrl.guestPagination, args);
            
            // If args has a resetFilter flag, reset pagination but preserve sorting
            if (args && args.resetFilter) {
              var sort = pagination.sort; // Save the sort parameter if it exists
              pagination = { 
                per_page: $ctrl.guestPagination.per_page, 
                page: 1 
              };
              // Restore sort parameter if it existed
              if (sort) {
                pagination.sort = sort;
              }
              // Remove the resetFilter flag as it's not needed for the API
              delete pagination.resetFilter;
            }
            
            var requests = [store.getGuests(pagination, true)];
            if (!args) {
              requests.push(store.getUsers());
            }
            $q.all(requests).then(function (res) {
              allGuests = res[0].data;
              angular.extend($ctrl.guestPagination, res[0].meta.pagination);
              if (!args) {
                $ctrl.allUsers = res[1] || [];
                $ctrl.availableUsers = UsersService.getBilledUsers($ctrl.allUsers);
                $ctrl.tableOptions.templateScope.variables.availableUsers = $ctrl.availableUsers;
              }
              var data = _.filter(res[0].data, function (guest) {
                return guest.email !== 'all';
              });
              $ctrl.isLoading = false;
              defer.resolve(data);
            }, function () {
              $ctrl.isLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function getCadenceDays(e, uid, day) {
            var user = _.find($ctrl.guestCadencesTableOptions.gridConfig.dataSource.data(), { uid: uid });
            return _.includes(user.details.cadence_days, day);
          }

          function getCadenceDaysClick(e, uid, day) {
            var guest = _.find($ctrl.guestCadencesTableOptions.gridConfig.dataSource.data(), { uid: uid });
            $ctrl.onSaving = true;
            if (day && !_.includes(guest.details.cadence_days, day)) {
              guest.details.cadence_days.push(day);
            } else if (day && _.includes(guest.details.cadence_days, day)) {
              guest.details.cadence_days = _.remove(guest.details.cadence_days, function (d) { return d !== day; });
            }
            if (guest.details.cadence_days.length < 1) {
              guest.details.cadence_days.push('Monday');
            }
            var guestLinkPathInArray = guest.link.split('/'), guestToken = guestLinkPathInArray[guestLinkPathInArray.length - 2];
            OrganizationsService.updateGuestCadenceDays({
              action: 'guests',
              id: guestToken,
              sub_action: 'cadence_days'
            }, { cadence_days: guest.details.cadence_days }).then(function () {
              $ctrl.onSaving = false;
              growl.success($filter('translate')('myAccount.messages.update.preferences'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            });
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function initCadencesTableConfig() {
            $ctrl.guestCadencesTableOptions = getCadencesTableConfig();
          }

          function onAssociatedMembersClick(e, uid, isSave) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            if (isSave) {
              if ((user.details.associated_members || []).length !== ($ctrl.tableOptions.templateScope.variables.editedGuest.details.associated_members || []).length) {
                saveAssociatedMembers(user);
              } else {
                var usersRemoved = _.compact(_.map(user.details.associated_members, function (oldId) {
                  return _.findIndex($ctrl.tableOptions.templateScope.variables.editedGuest.details.associated_members, function (newId) {
                    return oldId === newId;
                  }) === -1 ? newId : void 0;
                }));
                var usersAdded = _.compact(_.map($ctrl.tableOptions.templateScope.variables.editedGuest.details.associated_members, function (newId) {
                  return _.findIndex(user.details.associated_members, function (oldId) {
                    return oldId === newId;
                  }) === -1 ? newId : void 0;
                }));
                if (!usersRemoved.length && !usersAdded.length) {
                  $ctrl.tableOptions.templateScope.variables.editedGuest = void 0;
                } else {
                  saveAssociatedMembers(user);
                }
              }
            } else {
              $ctrl.tableOptions.templateScope.variables.editedGuest = angular.copy(user);
            }
          }

          function saveAssociatedMembers(user) {
            angular.extend(user, $ctrl.tableOptions.templateScope.variables.editedGuest);
            $ctrl.tableOptions.templateScope.variables.editedGuest = void 0;
            OrganizationsService.saveGuestData(
              { id: user.email },
              { email: user.email, associated_members: user.details.associated_members || [] }
            ).then(function (res) {
              showGrowlMessage($filter('translate')('regularUsers.associatedMember.dataSaved'));
            });
          }

          function getAssociatedMembers(e, uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return user.details.associated_members || [];
          }

          function convertGuestToMember(e, uid) {
            var user = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            OrganizationsService.convertGuestToMember({ id: user.email })
              .then(function (res) {
                $state.go('userProfile', {
                  org_id: _.get($rootScope, 'identity.default_organization.id'),
                  id: res.data.id
                });
              });
          }

          function getTableConfig() {
            var hideAndShowColumns = [{
              field: 'email',
              title: $filter('translate')('regularUsers.table.header.email'),
              initState: true
            }, {
              field: 'enabled',
              title: $filter('translate')('regularUsers.table.header.enabled'),
              initState: true
            }, {
              field: 'associatedMembers',
              title: $filter('translate')('regularUsers.table.header.associatedMembers'),
              initState: true
            }, {
              field: 'tasks',
              title: $filter('translate')('regularUsers.table.header.tasks'),
              initState: true
            }, {
              field: 'emailLogs',
              title: $filter('translate')('regularUsers.label.emailLogs'),
              initState: true
            }, {
              field: 'guestLink',
              title: $filter('translate')('regularUsers.label.guestLink'),
              initState: true
            }, {
              field: 'lastAccessed',
              title: $filter('translate')('regularUsers.table.header.lastAccessed'),
              initState: true
            }, {
              field: 'lastCity',
              title: $filter('translate')('regularUsers.table.header.lastCity'),
              initState: true
            }, {
              field: 'createdAt',
              title: $filter('translate')('regularUsers.table.header.firstCreated'),
              initState: true
            }, {
              field: 'lastCountry',
              title: $filter('translate')('regularUsers.table.header.lastCountry'),
              initState: true
            }];
            if ($ctrl.isAdminMember) {
              hideAndShowColumns.splice(6, 0, {
                field: 'convertToMember',
                title: $filter('translate')('regularUsers.label.convertToMember'),
                initState: true
              });
            }
            return {
              beautyScrollStyle: true,
              gridConfig: GuestTableService.getGuestTableOptions($ctrl.isAdminMember),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: hideAndShowColumns
              },
              tableFilterModel: {
                email: {
                  value: '',
                  operator: 'contains'
                },
                create_at: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  showEmailLogs: showEmailLogs,
                  viewTasks: viewTasks,
                  copyGuestLink: copyGuestLink,
                  enableLink: enableLink,
                  disableLink: disableLink,
                  onAssociatedMembersClick: onAssociatedMembersClick,
                  getAssociatedMembers: getAssociatedMembers,
                  convertGuestToMember: convertGuestToMember,
                  confirmDeleteGuest: confirmDeleteGuest,
                  sortTable: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    if ($ctrl.tableOptions.tableState.sort.field !== column) {
                      $ctrl.tableOptions.tableState.sort.dir = 'asc';
                      $ctrl.tableOptions.tableState.sort.field = column;
                    } else {
                      if ($ctrl.tableOptions.tableState.sort.dir === 'desc') {
                        $ctrl.tableOptions.tableState.sort = {};
                      } else {
                        $ctrl.tableOptions.tableState.sort.dir = $ctrl.tableOptions.tableState.sort.dir !== 'asc' ? 'asc' : 'desc';
                        $ctrl.tableOptions.tableState.sort.field = column;
                      }
                    }
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var sort = !Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort) ? 
                      ($ctrl.tableOptions.tableState.sort.dir !== 'asc' ? '-' : '') + column : 
                      undefined;
                    
                    // Create params object with sort and pagination
                    var params = { 
                      sort: sort,
                      page: 1 
                    };
                    
                    // Preserve email filter if it exists
                    if ($ctrl.tableOptions.tableFilterModel.email && $ctrl.tableOptions.tableFilterModel.email.value) {
                      params.email = $ctrl.tableOptions.tableFilterModel.email.value;
                    }
                    
                    getGuests(params).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  },
                  onColumnFilter: function (column) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var params = { page: 1 };
                    
                    // Preserve sorting parameters if they exist
                    if (!Helper.isObjectEmpty($ctrl.tableOptions.tableState.sort)) {
                      var sortField = $ctrl.tableOptions.tableState.sort.field;
                      var sortDir = $ctrl.tableOptions.tableState.sort.dir;
                      params.sort = (sortDir !== 'asc' ? '-' : '') + sortField;
                    }
                    
                    if (column === 'email') {
                      if ($ctrl.tableOptions.tableFilterModel.email.value) {
                        params.email = $ctrl.tableOptions.tableFilterModel.email.value;
                      } else {
                        // Only reset the email filter parameter, but preserve other parameters
                        delete params.email;
                        if (Object.keys(params).length === 1 && params.page) {
                          params.resetFilter = true;
                        }
                      }
                    }
                    
                    getGuests(params).then(function (res) {
                      $ctrl.tableOptions.gridConfig.dataSource.data(res);
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    }, function () {
                      $ctrl.isLoading = false;
                      kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                    });
                  }
                },
                variables: {
                  editedGuest: void 0,
                  isOnEdit: false,
                  cadenceDays: OrganizationsService.getCadenceDays(),
                  dateFormat: OrganizationsService.getDateFormat()
                }
              }
            };
          }

          function getCadencesTableConfig() {
            var hideAndShowColumnsInCadences = [{
              field: 'email',
              title: $filter('translate')('regularUsers.table.header.email'),
              initState: true
            }, {
              field: 'cadenceDays',
              title: $filter('translate')('regularUsers.table.header.cadenceDays'),
              initState: true
            }];

            return {
              beautyScrollStyle: true,
              gridConfig: GuestTableService.getGuestTableOptions($ctrl.isAdminMember, 'cadences'),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: hideAndShowColumnsInCadences
              },
              tableFilterModel: {
                email: {
                  value: '',
                  operator: 'contains'
                }
              },
              templateScope: {
                callbacks: {
                  getCadenceDays: getCadenceDays,
                  getCadenceDaysClick: getCadenceDaysClick,
                  cadenceDays: OrganizationsService.getCadenceDays()
                },
                variables: {
                  editedGuest: void 0,
                  isOnEdit: false,
                  cadenceDays: OrganizationsService.getCadenceDays()
                }
              }
            };
          }

          function createGuest() {
            $uibModal.open({
              backdrop: 'static',
              component: 'createGuestComponent',
              windowClass: 'create-guest-wrapper'
            });
          }

          function confirmDeleteGuest(uid) {
            var guest = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) || {};
            $confirm({
              'header': $filter('translate')('permanentlyDeleteItem.header', {
                itemName: guest.email
              }),
              'body': '',
              'buttons': {
                'accept': 'global.confirmAction.buttons.yes',
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              deleteGuest(guest, uid);
            }, function () {
              $log.info('Guest deletion is cancelled.');
            });
          }

          function deleteGuest(guest, uid) {
            var params = {
              action: 'guests',
              id: guest.id
            };
            blockUI.start();
            UsersService.permanentlyDeleteMemberOrGuest(params).then(function () {
              var tableData = $ctrl.tableOptions.gridConfig.dataSource.data();
              $ctrl.tableOptions.gridConfig.dataSource.data(
                _.filter(tableData, function (data) {
                  return data.uid !== uid;
                })
              );
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              store.removeGuest(guest);
              store.getLightweightGuests().then(function (res) {
                $rootScope.$emit('COUNT_UPDATE', { type: 'guests', count: res.length });
              });
              showGrowlMessage($filter('translate')('permanentlyDeleteItem.successMessage', { itemName: guest.email }));
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * Un-register listener for update organization member
           * @type {*|(function())}
           */
          unregisterUpdateOrganizationMembers = $rootScope.$on('USER:UPDATE_USERS', function (e, data) {
            if (data.guest) {
              $ctrl.tableOptions.gridConfig.dataSource.add(data.guest);
              $ctrl.guestCadencesTableOptions.gridConfig.dataSource.add(data.guest);
              $ctrl.tableOptions.gridConfig.dataSource.sync();
              $ctrl.guestCadencesTableOptions.gridConfig.dataSource.sync();
              $ctrl.tableOptions.tableElement.data('kendoGrid').refresh();
              $ctrl.guestCadencesTableOptions.tableElement.data('kendoGrid').refresh();
            }
            $rootScope.$emit('COUNT_UPDATE', { type: 'guests', count: $ctrl.tableOptions.gridConfig.dataSource._total });
            $rootScope.$emit('COUNT_UPDATE', { type: 'guests', count: $ctrl.guestCadencesTableOptions.gridConfig.dataSource._total });
          });
        }
    });
})();