(function () {
	'use strict';
	angular.module('tallyfy')
		.component('filterPill', {
			bindings: {
				key: '<',
				value: '<',
				usersInOrg: '<',
				guestsInOrg: '<',
				groupsInOrg: '<?',
				rolesInOrg: '<?',
				foldersList: '<',
				allTags: '<',
				pillClicked: '&',
				removeFilter: '&'
			},
			templateUrl: 'app/components/task-filter/task-filter-display/filter-pill/filter-pill.component.html',
			controller:
				/*@ngInject*/
				function (_, $rootScope, ProcessService, TagsService, TasksFilterService, Helper, RunsService) {
					var $ctrl = this;

					$ctrl.$onInit = onInit;
					$ctrl.$onChanges = onChanges;
					$ctrl.$onDestroy = onDestroy;

					$ctrl.pillDelete = pillDelete;
					$ctrl.valueRemoved = valueRemoved;
					$ctrl.elementClicked = elementClicked;

					$ctrl.getTagTextColor = TagsService.isTagTextColor;

					function onInit() {
						generateFilterValueInfo();
					}

					function onChanges(changes) {
						if (changes.value) {
							generateFilterValueInfo();
						}
					}

					function onDestroy() { }

					function generateFilterValueInfo() {
						if ($ctrl.key === 'assignee') {
							if (Number($ctrl.value) === $rootScope.identity.id) {
								$ctrl.assignee = void 0;
								$ctrl.value = 'mine';
							} else {
								if ($ctrl.value !== 'by-me' && $ctrl.value !== 'mine' && $ctrl.value !== 'at-mentioned' && $ctrl.value !== 'anyone' && $ctrl.value !== 'unassigned') {
									$ctrl.assignee = _.find(
										_.concat($ctrl.usersInOrg, $ctrl.guestsInOrg),
										function (user) {
											return user.id === Number($ctrl.value) || user.email === $ctrl.value;
										}
									);
									if (!$ctrl.assignee) {
										$ctrl.removeFilter({ dataKey: $ctrl.key });
									}
								} else if ($ctrl.value === 'anyone') {
									$ctrl.assignee = void 0;
								}
							}
						} else if ($ctrl.key === 'states') {
							var allStates = TasksFilterService.getTaskStates();
							$ctrl.filterInfos = $ctrl.value.split(',');
							for (var i = 0; i < $ctrl.filterInfos.length; i++) {
								var state = _.find(allStates, { value: $ctrl.filterInfos[i] });
								if (!state) {
									valueRemoved(null, $ctrl.filterInfos[i]);
								}
							}
						} else if ($ctrl.key === 'folder') {
							for (var i = 0; i < $ctrl.foldersList.length; i++) {
								$ctrl.folder = folderSearch($ctrl.value, $ctrl.foldersList[i]);
								if ($ctrl.folder) {
									break;
								}
							}
							if (!$ctrl.folder) {
								pillDelete();
							}
						} else if ($ctrl.key === 'tags') {
							var tags = $ctrl.value.split(',');
							$ctrl.filterInfos = [];
							for (var i = 0; i < tags.length; i++) {
								var tagData = _.find($ctrl.allTags, function (tag) {
									return tag.id === tags[i];
								});
								if (tagData) {
									$ctrl.filterInfos.push(tagData);
								} else {
									valueRemoved(null, tags[i]);
								}
							}
						} else if ($ctrl.key === 'blueprint') {
							ProcessService.get({
								id: $ctrl.value,
								with: 'linked_tasks',
								skipNotFound: true
							}).then(function (res) {
								if (!Helper.isObjectEmpty(res.data)) {
									$ctrl.blueprintData = res.data;
								} else {
									pillDelete();
								}
							}, function () {
								pillDelete();
							});
						} else if ($ctrl.key === 'process') {
							RunsService.get({ id: $ctrl.value, skipNotFound: true })
								.then(function (res) {
									$ctrl.processObj = res.data;
									if (!$ctrl.processObj) {
										pillDelete();
									}
								}, function () {
									pillDelete();
								});
						} else if ($ctrl.key === 'status') {
							if ($ctrl.value !== 'to-do' && $ctrl.value !== 'completed' && $ctrl.value !== 'all') {
								pillDelete();
							}
						} else if (_.includes(['role', 'group'], $ctrl.key)) {
							if ($ctrl.key === 'group') {
								$ctrl.groupValue = _.find($ctrl.groupsInOrg, { id: $ctrl.value });
							} else if ($ctrl.key === 'role') {
								$ctrl.roleValue = _.find($ctrl.rolesInOrg, { id: $ctrl.value });
							}
						}
					}

					function folderSearch(id, folder) {
						if (folder.id === id) {
							return folder;
						} else if (_.get(folder, 'children.data', []).length) {
							var result;
							for (var i = 0; i < folder.children.data.length; i++) {
								result = folderSearch(id, folder.children.data[i]);
							}
							return result;
						}
					}

					function valueRemoved(e, value) {
						if (e) {
							e.stopImmediatePropagation();
						}
						$ctrl.value = _.filter($ctrl.value.split(','), function (val) {
							return value !== val;
						}).join(',');
						$ctrl.removeFilter({ dataKey: $ctrl.key, dataValue: $ctrl.value });
					}

					function pillDelete(e) {
						if (e) {
							e.stopImmediatePropagation();
						}
						$ctrl.removeFilter({ dataKey: $ctrl.key });
					}

					function elementClicked(e) {
						$ctrl.pillClicked({ e: e });
					}
				}
		});
})();
