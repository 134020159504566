/**
 * @ngdoc Component
 * @name tallyfy.steps.component.description
 * @module tallyfy.steps
 *
 * @description
 * A component to manage describe tab
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('stepSettings', {
      bindings: {
        process: '<',
        step: '<',
        form: '=',
        isPublic: '<',
        type: '@'
      },
      templateUrl: 'app/modules/steps/settings/settings.html',
      controller:
        /*@ngInject*/
        function (_, $scope, $q, CONST, $rootScope, AuthPlan, PLANS, Helper, StepService, $sce, $filter, ProcessService, blockUI, FroalaService, BLUEPRINT_TYPE, CONFIG, COMMON, Growl) {
          var $ctrl = this,
            oldStep,
            blockUI = blockUI.instances.get('autoLaunchUI'),
            pagination = {
              page: 1,
              per_page: 20
            }, selectedChecklistWatcher,
            growl = new Growl();

          $ctrl.launchAnotherProcessWhenComplete = false;
          $ctrl.displayedChecklists = [];
          $ctrl.onChecklistLoading = false;
          $ctrl.selectedChecklist = void 0;
          $ctrl.isSelectBoxChecklistOpen = false;
          $ctrl.froalaOptions = {};
          $ctrl.maxAutonameTitleLength = COMMON.MAX_AUTOMATION_NAME;
          $ctrl.checkMaxTitleValidation = false;
          
          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.toggleWebhook = toggleWebhook;
          $ctrl.onWebhookChanges = onWebhookChanges;
          $ctrl.openUpgradeModal = openUpgradeModal;
          $ctrl.copyToClipboard = Helper.copyToClipboard;
          $ctrl.onToggleUpdate = onToggleUpdate;
          $ctrl.onDiscard = onDiscard;
          $ctrl.updateStep = updateStep;
          $ctrl.onChecklistSelected = onChecklistSelected;
          $ctrl.onClearSelectedChecklist = onClearSelectedChecklist;
          $ctrl.onChecklistDropdownOpenHandler = onChecklistDropdownOpenHandler;
          $ctrl.getNextChecklist = getNextChecklist;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            $ctrl.apiUrl = CONFIG.API_HOST;
            $ctrl.URL_REGEX = CONST.URL_REGEX;
            $ctrl.isRestricted = AuthPlan.isRestricted(PLANS.RESTRICATED_PLANS);
            $ctrl.htmlPopoverWebhookHelpText = $sce.trustAsHtml($filter('translate')('steps.settings.messages.webhookHelpText'));
            oldStep = angular.copy($ctrl.step);
            if ($ctrl.step.webhook) {
              $ctrl.step.webhookEnable = true;
            }
            $ctrl.launchAnotherProcessWhenComplete = !Helper.isObjectEmpty($ctrl.step.bp_to_launch);
            if ($ctrl.launchAnotherProcessWhenComplete) {
              getAutoLaunchChecklistData();
            }
            $ctrl.froalaOptions = Helper.getFroalaOptions({
              allowEdit: $ctrl.haveAuthority,
              autofocus: false,
              initOnClick: !$ctrl.launchAnotherProcessWhenComplete,
              placeholder: $filter('translate')('steps.settings.placeholders.processToLaunchNameFormat'),
              currentUser: $rootScope.identity,
              heightMin: 80,
              heightMax: 80,
              pluginsEnabled: ['insertVariable'],
              toolbar: ['insertVariableDropdown'],
              enableFullscreen: true,
              qAutoSave: {
                callback: onDefaultNameProcessUpdate,
                debounce: 750
              },
              hideHtmlToggle: true
            }, true);
            $ctrl.froalaOptions.variableValue = prepareInsertVariables($ctrl.process.prerun || []);
            $ctrl.froalaOptions.upgradeModal = upgradeModal;
            $ctrl.maxTitleLength = COMMON.MAX_AUTOMATION_NAME;
            getNextChecklist();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            selectedChecklistWatcher();
          }

          function onWebhookChanges() {
            if ($ctrl.parent) {
              $ctrl.form.$setDirty();
              $ctrl.step.isWebhookRequired = $ctrl.form.webhookURL.$invalid || $ctrl.form.alias.$invalid;
            }
          }

          function toggleWebhook() {
            $ctrl.step.isWebhookRequired = false;
            if ($ctrl.step.webhookEnable) {
              onWebhookChanges();
              return;
            }
            if (!$ctrl.step.webhookEnable) {
              $ctrl.form.$setDirty();
              $ctrl.step.webhook = '';
              updateSetting(true);
            }
            $ctrl.form.$submitted = false;
          }

          /**
           * @ngdoc method
           * @name onToggleUpdate
           * @description applied changes on toggle on/off 
           * @param {String} configName
           */
          function onToggleUpdate(configName) {
            if (configName === 'everyone_must_complete' && $ctrl.step.everyone_must_complete) {
              $ctrl.step.can_complete_only_assignees = $ctrl.step.everyone_must_complete;
              updateSetting();
            } else if (configName === 'launch_another_process_when_complete') {
              if (!$ctrl.launchAnotherProcessWhenComplete) {
                $ctrl.selectedChecklist = void 0;
              }
              $ctrl.step.bp_to_launch = {};
              saveStepValue();
            } else if (configName === 'task_within_process') {
              if ($ctrl.selectedChecklist.id) {
                saveStepValue();
              }
            } else {
              updateSetting();
            }
          }

          /**
           * @ngdoc method
           * @name updateSetting
           * @description save updated changes
           * @param {String} isWebhook
           */
          function updateSetting(isWebhook) {
            var dataToSave;
            if (isWebhook) {
              $ctrl.form.$setPristine();
              dataToSave = $ctrl.step;
            } else {
              dataToSave = _.omit($ctrl.step, ['webhook', 'webhookEnable']);
            }
            StepService.updateStep({
              id: dataToSave.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, dataToSave).then(function (response) {
              oldStep = angular.copy(response.data);
              if (isWebhook || !($ctrl.form && _.get($ctrl.form, 'webhookEnable.$dirty'))) {
                $ctrl.form.$setPristine();
              }
              $rootScope.$emit('STEP:UPDATED', response.data);
              Helper.showChangesSavedGrowl();
            });
          }

          /**
           * @ngdoc method
           * @name openUpgradeModal
           * @description Open upgrade modal
           */
          function openUpgradeModal() {
            if (!AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'stepAdvancedTab')) {
              return;
            }
          }

          function onDiscard() {
            _.extend($ctrl.step, oldStep);
            if (!$ctrl.step.webhook) {
              $ctrl.step.webhookEnable = false;
            }
            $ctrl.form.$setPristine();
          }

          function updateStep() {
            var isValid = true;
            if ($ctrl.step.webhookEnable) {
              if (!$ctrl.step.webhook) {
                isValid = false;
              }
            } else {
              $ctrl.step.webhook = '';
            }
            if (isValid) {
              $ctrl.onSaving = true;
              StepService.updateStep({
                id: $ctrl.step.id,
                checklist_id: $ctrl.process.id,
                skipNotFound: true
              }, $ctrl.step).then(function (response) {
                oldStep = angular.copy($ctrl.step);
                $ctrl.form.$setPristine();
                $rootScope.$emit('STEP:UPDATED', response.data);
                $ctrl.onSaving = false;
                Helper.showChangesSavedGrowl();
              }, function () {
                $ctrl.onSaving = false;
              });
            } else {
              $ctrl.form.$setSubmitted();
            }
          }

          function onClearSelectedChecklist() {
            $ctrl.selectedChecklist = void 0;
            $ctrl.step.bp_to_launch = {};
            $ctrl.launchAnotherProcessWhenComplete = false;
            saveStepValue();
          }

          function onChecklistDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxChecklistOpen = false;
            }
          }

          function onChecklistSelected(item) {
            if (!item) {
              $ctrl.step.bp_to_launch = {};
            }
          }

          function getNextChecklist(args) {
            var init = _.get(args, 'init', false);
            if (init) {
              $ctrl.displayedChecklists = [];
              $ctrl.allBlueprintsLoaded = false;
              pagination = {
                page: 1,
                per_page: 20
              };
            }
            var defer = $q.defer();
            if ($ctrl.allBlueprintsLoaded) {
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
              return defer.promise;
            }
            $ctrl.onBlueprintsLoading = true;
            var params = angular.extend({
              sort: 'title',
              with: 'tags',
              append: 'steps_count',
              q: _.get(args, 'query')
            }, pagination);
            ProcessService.filter(params).then(function (res) {
              var data = _.uniqBy(_.concat($ctrl.displayedChecklists || [], res.data), 'id');
              $ctrl.displayedChecklists = _.filter(data || [], isBlueprintLaunchAble);
              res.meta.pagination.current_page !== res.meta.pagination.total_pages ? pagination.page++ : $ctrl.allBlueprintsLoaded = true;
              $ctrl.onBlueprintsLoading = false;
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintsLoaded });
            }, function () {
              $ctrl.onBlueprintsLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function isBlueprintLaunchAble(bp) {
            return ((bp.steps_count > 0 && _.get(bp, 'type') === BLUEPRINT_TYPE.PROCEDURE)
              || (bp.steps_count === 0 && bp.prerun.length && _.get(bp, 'type') === BLUEPRINT_TYPE.FORM))
              && !(_.get(bp, 'type') === BLUEPRINT_TYPE.DOCUMENT)
              && !(_.filter(bp.prerun || [], { required: true }).length > 0);
          }

          function prepareInsertVariables(preruns) {
            var variables = [{
              id: 'DATE',
              label: $filter('translate')('steps.captures.form.label.fields.date'),
              full_label: $filter('translate')('steps.captures.form.label.fields.date')
            }, {
              id: 'TEMPLATE_NAME',
              label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP'),
              full_label: $filter('translate')('global.bluePrintModal.info.typeUniqueNameBP')
            }];
            if (!_.isEmpty(preruns)) {
              variables.push({
                name: $filter('translate')('steps.logic.label.pre_run'),
                type: 'Prerun',
                capture: truncateFields(preruns) || []
              });
            }
            return variables;
          }

          function truncateFields(fields) {
            if (!_.isArray(fields) || _.isEmpty(fields)) {
              return [];
            }
            return _.map(_.filter(fields, { field_type: 'text' }) || [], function (field) {
              return angular.extend(field, {
                full_label: field.label,
                label: truncateName(field.label, 25),
                type_field: $filter('translate')('steps.describes.insertVariable.' + field.field_type) + " " + field.alias.split('-').pop()
              });
            });
          }

          function truncateName(name, length) {
            if (_.size(name) > length) {
              return name.substring(0, length) + '...';
            }
            return name;
          }

          function upgradeModal() {
            AuthPlan.hasAnyAuthority(PLANS.RESTRICATED_PLANS, 'insertVariable');
          }

          function getAutoLaunchChecklistData() {
            var checklistId = _.get($ctrl.step, 'bp_to_launch.id');
            $ctrl.tasksWithinProcess = _.get($ctrl.step, 'bp_to_launch.tasks_within_process');
            $ctrl.defaultAutoLaunchProcessName = FroalaService.encodeVariable(_.get($ctrl.step, 'bp_to_launch.default_name_format') || '');
            var variables = $ctrl.defaultAutoLaunchProcessName.match(CONST.DOUBLE_CURLY_REGEX) || [];
            variables.forEach(function (variable) {
              $ctrl.defaultAutoLaunchProcessName = $ctrl.defaultAutoLaunchProcessName.replace(variable, '\uFEFF<span class="insert-variable-tag fr-deletable" contenteditable="false">' + variable + '</span>\uFEFF');
            });
            if (checklistId) {
              blockUI.start();
              ProcessService.get({
                id: checklistId,
                skipNotFound: true
              }).then(function (res) {
                $ctrl.selectedChecklist = res.data;
                blockUI.stop();
              }, function () {
                blockUI.stop();
              });
            }
          }

          function onDefaultNameProcessUpdate() {
            if ($ctrl.launchAnotherProcessWhenComplete && $ctrl.defaultAutoLaunchProcessName.length > $ctrl.maxAutonameTitleLength) {
              $ctrl.checkMaxTitleValidation = true;
              return true;
            } else {
              $ctrl.checkMaxTitleValidation = false;
            }
            if (_.get($ctrl.selectedChecklist, 'id')) {
              return saveStepValue();
            }
            return false;
          }

          function saveStepValue() {
            var defer = $q.defer();
            if (_.get($ctrl.selectedChecklist, 'id')) {
              if ($ctrl.defaultAutoLaunchProcessName && $ctrl.defaultAutoLaunchProcessName.length > $ctrl.maxAutonameTitleLength) {
                growl.error($filter('translate')('steps.settings.messages.launchAnotherProcessNameError'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                return;
              }
              $ctrl.step.bp_to_launch = {
                id: $ctrl.selectedChecklist.id,
                default_name_format: FroalaService.decodeVariable($ctrl.defaultAutoLaunchProcessName),
                tasks_within_process: $ctrl.tasksWithinProcess
              };
            }
            _.forEach($ctrl.step.captures, function(capture) {
              if (_.isEmpty(capture.default_value)) {
                capture.default_value_enabled = false;
              }
            });
            StepService.updateStep({
              id: $ctrl.step.id,
              checklist_id: $ctrl.process.id,
              skipNotFound: true
            }, $ctrl.step).then(function (res) {
              oldStep = angular.copy($ctrl.step);
              $ctrl.form.$setPristine();
              $rootScope.$emit('STEP:UPDATED', res.data);
              Helper.showChangesSavedGrowl();
              defer.resolve(res);
            }, function (err) {
              defer.reject(err);
            });
            return defer.promise;
          }

          selectedChecklistWatcher = $scope.$watch('$ctrl.selectedChecklist', function (value) {
            if (!Helper.isObjectEmpty(value)) {
              $ctrl.selectedChecklist = value;
              $ctrl.step.bp_to_launch = {
                id: value.id,
                default_name_format: $ctrl.defaultAutoLaunchProcessName
              };
              saveStepValue();
            }
          });
        }
    });
})();
