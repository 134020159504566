/**
 * @ngdoc component
 * @name tallyfy.compactTaskList
 * @restrict 'A'
 *
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactTaskList', {
      templateUrl: 'app/components/compact/components/taskList/compact-task-list.component.html',
      bindings: {
        isGuest: '<?',
        isPublicProcess: '<',
        process: '<?',
        usersInOrg: '<',
        playSoundOnStatusChanged: '<?',
        isBusy: '=?',
        selectedItem: '=',
        selectedIndex: '=',
        tasksFiltersConfig: '=?',
        taskRootConfig: '=?',
        editMode: '=?',
        showCreateTaskButton: '<',
        forceOnboarding: '<?',
        onTaskComplete: '&?',
        orgGroups: '<?',
        displayedTasks: '=?',
        additionalRequestParams: '<?',
        taskLength: '=?',
        foldersList: '<?',
        metadata: '=?',
        saveSelectedTask: "&?",
        qDiscardTaskCallback: '&?',
        autoLoadProfileImage: '<?',
        stage: '<?',
        koTaskOnly: '<?',
        unmappedStage: '<?',
        startIndex: '<?',
        activeTask: '<?',
        initTasks: '<',
        skipFirstCall: '<'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, $filter, $uibModal, $window, $q, SoundService, OrganizationsService, CompactTaskService, USER_STATE,
          DateUtils, DOMService, TasksFilterService, Helper, TASKORDER, ProgressBar, PLANS, AuthPlan, TasksService, ProcessService, blockUI,
          $state, MilestoneService, COMMON) {
          var $ctrl = this,
            actualTasks = [],
            timeoutLoading,
            taskSwitchedTimeoutHandler,
            unRegisterConfigWatcher,
            selectedItemThreadsWatcher,
            unregisteredIssueReportedEventHandler,
            unregisteredIssueResolvedEventHandler,
            unRegisterHiddenTaskConfig,
            unregisterUpdateCaptureListener,
            unregisteredUnlinkToProcess,
            unRegisterAddTaskEventHandler,
            unRegisterRestoreTaskEventHandler,
            unRegisterDeleteTaskEventHandler,
            onFetchPreviousHandler,
            onFetchNextHandler,
            unregisteredTaskUpdateHandler,
            firstSort = true,
            postCommentUpdateHandler,
            filterWatcher,
            koFieldAssigneeClickEventHandler,
            compactTaskCreatedHandler,
            taskUnLinkedWatcherHandler,
            taskLinkedWatcherHandler,
            onTaskCompletedHandler,
            scrollPageFetchTimeout,
            unregisterTaskLinkHandler,
            unregisterRightPaneOpenHandler,
            initialPageLoad,
            unregisterRightPaneCloseHandler,
            dependentTaskCheck,
            processTasksLoadedHandler;

          /**
           * angularjs lifecycle hook
           */
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          /**
           * Public properties;
           */
          $ctrl.sortableOptions = {
            disabled: true
          };
          $ctrl.direction = 'next';
          $ctrl.metadata = {
            previous: {},
            next: {}
          };
          $ctrl.cacheTasks = {
            previous: {},
            next: {}
          };
          $ctrl.stageCollpaseOpen = {};
          $ctrl.stageMenu = {};

          /**
           * Public method handlers
           */
          $ctrl.onPaneClose = onPaneClose;
          $ctrl.onPageFetch = onPageFetch;
          $ctrl.standaloneModal = TasksService.standaloneModal;
          $ctrl.onTooltipClose = onTooltipClose;
          $ctrl.loadMore = loadMore;
          $ctrl.openInModal = openInModal;

          /**
           * @ngdoc method
           * @name onInit
           *
           * @description
           * angular life cycle hook method
           */
          function onInit() {
            $ctrl.compactTaskViewConfig = {
              mainSection: $ctrl.process ? '.run-compact-task' : '.main-section',
              rightSection: {
                className: '.right-section'
              },
              viewContainer: '.app-body',
              isFirstLoad: true
            };
            $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT_LEFT_PANE;
            $ctrl.isProPlan = ([PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
            $ctrl.isForceOnboarding = ProcessService.isForceOnboarding();
            if ($ctrl.isGuest) {
              getGuestOrganization()
                .then(function (res) {
                  if (res.data.guest_onboarding_snippet) {
                    OrganizationsService.getTextTemplate(res.data.guest_onboarding_snippet, true, $rootScope.userState)
                      .then(function (res) {
                        $ctrl.showGuestSnippet = true;
                        $ctrl.guestSnippet = res.data;
                      });
                  }
                });
            } else {
              $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
            }
            if ($ctrl.koTaskOnly) {
              angular.extend($ctrl.compactTaskViewConfig, { isSplashing: true });
              return;
            }
            firstLoadTasks();
            if (!$ctrl.process) {
              filterWatcher = $rootScope.$on('TASK:FILTER_CONFIG', function () {
                $ctrl.taskLength = 0;
                clearSelection();
                $rootScope.$emit('RIGHT_PANE:CLOSE', {});
                firstLoadTasks();
              });
            }
          }

          function onChanges(changes) {
            if ($ctrl.koTaskOnly) {
              return;
            }
            if (changes.displayedTasks && !changes.displayedTasks.currentValue) {
              $ctrl.displayedTasks = [];
            }
            if (changes.process) {
              if (changes.process.isFirstChange()) {
                $ctrl.sortableOptions = getSortableOptions();
              }
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           *
           * @description
           * angular life cycle hook method
           */
          function onDestroy() {
            onTaskCompletedHandler();
            selectedItemThreadsWatcher();
            unRegisterDeleteTaskEventHandler();
            unRegisterRestoreTaskEventHandler();
            unregisteredIssueReportedEventHandler();
            unregisteredIssueResolvedEventHandler();
            unRegisterConfigWatcher();
            unRegisterHiddenTaskConfig();
            unregisterUpdateCaptureListener();
            unregisteredUnlinkToProcess();
            unRegisterAddTaskEventHandler();
            unRegisterRestoreTaskEventHandler();
            unRegisterDeleteTaskEventHandler();
            onFetchPreviousHandler();
            onFetchNextHandler();
            taskUnLinkedWatcherHandler();
            taskLinkedWatcherHandler();
            unregisteredTaskUpdateHandler();
            unregisterTaskLinkHandler();
            filterWatcher ? filterWatcher() : angular.noop();
            timeoutLoading ? $timeout.cancel(timeoutLoading) : angular.noop();
            taskSwitchedTimeoutHandler ? $timeout.cancel(taskSwitchedTimeoutHandler) : angular.noop();
            postCommentUpdateHandler();
            compactTaskCreatedHandler();
            koFieldAssigneeClickEventHandler();
            scrollPageFetchTimeout ? $timeout.cancel(scrollPageFetchTimeout) : angular.noop();
            unregisterRightPaneCloseHandler();
            unregisterRightPaneOpenHandler();
            processTasksLoadedHandler();
            dependentTaskCheck();
          }

          function setFirstCachePreviousPage() {
            var defer = $q.defer();
            var pagination = _.get($ctrl.metadata.next, 'pagination');
            var params = $ctrl.process ? getProcessFilter(pagination) : TasksFilterService.getTasksRequestParams($ctrl.tasksFiltersConfig, pagination, $ctrl.isGuest);
            params.page = pagination.current_page > 1 ? pagination.current_page - 1 : 1;
            if ($rootScope.userState === USER_STATE.MEMBER) {
              if ($ctrl.stage) {
                params.stage = $ctrl.stage.id;
              }
              if ($ctrl.unmappedStage && !($ctrl.isGuest || $ctrl.isPublicProcess)) {
                params.without_stage = true;
              }
            }
            var resource = $ctrl.isGuest
              ? CompactTaskService.getGuestTasks(params)
              : ($ctrl.isPublicProcess ? CompactTaskService.getPublicProcessTasks(params) : CompactTaskService.getUserTasks(params));
            resource.then(function (response) {
              $ctrl.cacheTasks.previous = response;
              angular.extend($ctrl.metadata.previous, response.meta);
              defer.resolve();
            }, function () {
              defer.resolve();
            });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name getUserTaskSuccess
           * @param {*} response
           *
           * @description
           * get user task success callback
           */
          function getUserTaskSuccess(response) {
            if ($ctrl.isPageFetched) {
              ProgressBar.stop();
              angular.extend($ctrl.metadata[$ctrl.direction], _.get(response, 'meta') || { current_page: 0, total_pages: 0 });
              actualTasks = _.get(response, 'data') || [];
              if ($ctrl.process) {
                if (!$ctrl.metadata[$ctrl.direction].pagination.current_page && !$ctrl.metadata[$ctrl.direction].pagination.total_pages) {
                  $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.compactTaskViewConfig.isSplashing = $ctrl.isPageFetched = false;
                  return;
                }
                getKoFieldAssigneeTasks();
                loadRequest();
                if ($ctrl.compactTaskViewConfig.isFirstLoad) {
                  $ctrl.metadata[$ctrl.direction].visible_tasks_total = _.filter(response.data, function (task) {
                    return task.status !== 'auto-skipped';
                  }).length;
                  $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.isPageFetched = false;
                  initialPageLoad = $ctrl.metadata.next.pagination.current_page;
                  if ($ctrl.metadata.next.pagination.total_pages === 1) {
                    return;
                  }
                  $ctrl.metadata.next.pagination.current_page = -1;
                }
                if (initialPageLoad === $ctrl.metadata.next.pagination.current_page) {
                  $ctrl.metadata.next.pagination.current_page = initialPageLoad + 1;
                }
                if ($ctrl.metadata.next.pagination.current_page <= $ctrl.metadata.next.pagination.total_pages) {
                  actualTasks = _.uniqBy(_.concat(actualTasks, response.data), 'id');
                  filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
                  loadMore();
                }
              } else {
                if ($ctrl.metadata[$ctrl.direction].pagination.current_page > 1 && $ctrl.compactTaskViewConfig.isFirstLoad) {
                  $ctrl.compactTaskViewConfig.canLoadPreviousPage = true;
                  setFirstCachePreviousPage()
                    .then(loadRequest);
                } else {
                  loadRequest();
                }
              }
            } else {
              if (Helper.isObjectEmpty($ctrl.cacheTasks[$ctrl.direction])) {
                $ctrl.cacheTasks[$ctrl.direction] = response;
              }
              if ($ctrl.process) {
                actualTasks = _.uniqBy(_.concat(actualTasks, response.data), 'id');
                filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
                loadMore();
              } else {
                pendingInfiniteScroll();
                setMetadata();
              }
            }
          }

          function getKoFieldAssigneeTasks() {
            var preruns = _.get($ctrl.process, 'checklist.data.prerun', []);
            if (!preruns.length) {
              return;
            }
            for (var i = 0; i < actualTasks.length; i++) {
              var tempEl = angular.element('<div>');
              tempEl.html(actualTasks[i].summary);
              var koAssigneeElements = tempEl[0].getElementsByTagName('ko-field-assignee');
              if (koAssigneeElements.length) {
                var koField = _.find(preruns, { alias: koAssigneeElements[0].dataset.koFieldAlias.replace(/'/g, '') });
                if (!$ctrl.metadata.koFieldAssignee) {
                  $ctrl.metadata.koFieldAssignee = {};
                }
                if (!$ctrl.metadata.koFieldTaskAssignee) {
                  $ctrl.metadata.koFieldTaskAssignee = {};
                }
                $ctrl.metadata.koFieldAssignee[koField.id] = _.pick(actualTasks[i].owners, ['users', 'guests', 'groups']);
                $ctrl.metadata.koFieldTaskAssignee[koField.id] = actualTasks[i];
              }
            }
          }

          function loadRequest() {
            filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
            if ($ctrl.taskRootConfig.activeTask === 'ko-task') {
              if (!$ctrl.selectedItem) {
                $ctrl.selectedItem = {};
              }
              $ctrl.selectedItem.id = 'ko-task';
              $ctrl.selectedItem.process = $ctrl.process;
              $scope.$applyAsync(function () {
                onPageFetch(false);
                setMetadata();
              });
            } else {
              if ($ctrl.process) {
                splashingActiveTask()
                  .then(function () {
                    if ($ctrl.displayedTasks && $ctrl.displayedTasks.length >= 0) {
                      if (_.get($ctrl.activeTask, 'stage_id', null) == _.get($ctrl.stage, 'id', null)) {
                        var index = _.findIndex($ctrl.displayedTasks, { id: $ctrl.taskRootConfig.activeTask }),
                          selectedIndex = index <= 0 ? 0 : index;
                        if (selectedIndex >= 0) {
                          if (!$ctrl.selectedItem) {
                            $ctrl.selectedItem = {};
                          }
                          $ctrl.selectedItem.task = $ctrl.displayedTasks[selectedIndex];
                          $ctrl.selectedIndex = selectedIndex;
                        }
                      }
                    }
                    onBoardingCheck();
                    $scope.$applyAsync(function () {
                      onPageFetch(false);
                      setMetadata();
                    });
                  }, function () {
                    $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.compactTaskViewConfig.isSplashing = false;
                    onBoardingCheck();
                    $scope.$applyAsync(function () {
                      onPageFetch(false);
                      setMetadata();
                    });
                  });
              } else {
                $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.isPageFetched = false;
                if ($ctrl.displayedTasks && $ctrl.displayedTasks.length >= 0 && ($ctrl.metadata[$ctrl.direction].visible_tasks_total || _.get($ctrl.metadata[$ctrl.direction], 'pagination.total'))) {
                  var index = _.findIndex($ctrl.displayedTasks, { id: $ctrl.taskRootConfig.activeTask }),
                    selectedIndex = index <= 0 ? 0 : index;
                  if (selectedIndex >= 0) {
                    if (!$ctrl.selectedItem) {
                      $ctrl.selectedItem = {};
                    }
                    $ctrl.selectedItem.task = $ctrl.displayedTasks[selectedIndex];
                    $ctrl.selectedIndex = selectedIndex;
                    $rootScope.$emit('RIGHT_PANE:OPEN', {
                      item: {
                        task: $ctrl.selectedItem.task,
                        index: $ctrl.selectedIndex
                      }
                    });
                  }
                }
                onBoardingCheck();
                $scope.$applyAsync(function () {
                  onPageFetch(false);
                  $ctrl.isBusy = false;
                  setMetadata(5);
                });
              }
            }
          }

          /**
           * @ngdoc method
           * @name onBoardingCheck
           *
           * @description
           * on boarding data check
           */
          function onBoardingCheck() {
            if ($ctrl.forceOnboarding) {
              var index = _.findIndex($ctrl.displayedTasks, function (t) {
                return t.status !== 'completed';
              });
              _.set($ctrl.displayedTasks[index], 'showTooltip', true);
            }
          }

          /**
           * @ngdoc method
           * @name setMetadata
           *
           * @description
           * metadata config check
           */
          function setMetadata(l) {
            $ctrl.taskLength = _.get($ctrl.metadata[$ctrl.direction], 'pagination.total', 0);

            if ($ctrl.process) {
              if (_.get($ctrl.metadata.next, 'pagination.current_page', 0) === _.get($ctrl.metadata.next, 'pagination.total_pages', 0)) {
                $ctrl.compactTaskViewConfig.isSplashing = false;
              }
              $rootScope.$emit('PROCESS_TASKS:FETCH_END');
            } else {
              $rootScope.$broadcast('COMPACT_TASK:FETCH_END', { direction: $ctrl.direction, stage: $ctrl.stage });
            }
          }

          /**
           * @ngdoc method
           * @name getUserTaskError
           * @param {*} err
           *
           * @description
           * get user task error callback
           */
          function getUserTaskError(err) {
            if ($ctrl.isPageFetched) {
              $ctrl.compactTaskViewConfig.isFirstLoad = false;
            }
            $ctrl.compactTaskViewConfig.isSplashing = false;
            $rootScope.$emit('COMPACT_TASK:FETCH_ERROR', { error: err, stage: $ctrl.stage });
            pendingInfiniteScroll();
          }

          /**
           * @ngdoc method
           * @private
           * @name pendingInfiniteScroll
           *
           * @description
           * avoid immediate call next page when data loaded
           */
          function pendingInfiniteScroll() {
            timeoutLoading = $timeout(function () {
              if ($ctrl.isPageFetched) {
                $ctrl.isPageFetched = false;
              }
              $ctrl.isBusy = false;
            }, 0);
          }

          /**
           * @ngdoc method
           * @name getTasks
           * @param {*} params
           *
           * @description
           * get tasks from specifics filter params
           */
          function getTasks(params) {
            if ($ctrl.additionalRequestParams) {
              params = mergedWithAdditionalRequestParams(params);
            }
            if ($rootScope.userState === USER_STATE.MEMBER) {
              if ($ctrl.stage) {
                params.stage = $ctrl.stage.id;
              }
              if ($ctrl.unmappedStage && !($ctrl.isGuest || $ctrl.isPublicProcess)) {
                params.without_stage = true;
              }
            }
            var resource = null;
            if ($ctrl.isGuest) {
              resource = CompactTaskService.getGuestTasks(params);
            } else if ($ctrl.isPublicProcess) {
              resource = CompactTaskService.getPublicProcessTasks(params);
            } else {
              if ($ctrl.skipFirstCall && $ctrl.compactTaskViewConfig.isFirstLoad) {
                getUserTaskSuccess($ctrl.initTasks);
              } else {
                resource = CompactTaskService.getUserTasks(params);
              }
            }
            if (resource) {
              resource.then(getUserTaskSuccess, getUserTaskError);
            }
          }

          function mergedWithAdditionalRequestParams(params) {
            if ($ctrl.additionalRequestParams.with) {
              params.with = params.with + ',' + $ctrl.additionalRequestParams.with;
            }
            return params;
          }

          /**
           * @ngdoc method
           * @name onPageFetch
           * @param {*} isFirstLoad
           * @param {*} fetchPreviousPage
           *
           * @description
           * get next task for infinite scroll
           */
          function onPageFetch(isFirstLoad, fetchPreviousPage) {
            scrollPageFetchTimeout = $timeout(function () {
              $ctrl.isBusy = true;
              $ctrl.direction = fetchPreviousPage ? 'previous' : 'next';
              if (isFirstLoad) {
                ProgressBar.start();
                resetTaskList();
                paginationConfig(true);
              } else {
                if ($ctrl.process) {
                  if (!$ctrl.metadata.next.pagination) {
                    return;
                  }
                  $ctrl.metadata.next.pagination.current_page++;
                  paginationConfig();
                  $rootScope.$emit('COMPACT_TASK:FETCH_NEXT', { direction: 'next', stage: $ctrl.stage, unmappedStage: !$ctrl.stage });
                } else {
                  if (!Helper.isObjectEmpty($ctrl.cacheTasks[$ctrl.direction])) {
                    angular.extend($ctrl.metadata[$ctrl.direction], $ctrl.cacheTasks[$ctrl.direction].meta);
                    actualTasks = $ctrl.direction === 'next'
                      ? _.uniqBy(_.concat(actualTasks, $ctrl.cacheTasks[$ctrl.direction].data), 'id')
                      : _.reverse(_.uniqBy(_.reverse(_.concat($ctrl.cacheTasks[$ctrl.direction].data, actualTasks)), 'id'));
                    filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
                    if ($ctrl.process) {
                      splashingActiveTask()
                        .then(function () {
                          $ctrl.cacheTasks[$ctrl.direction] = {};
                          paginationConfig(false);
                        }, function () {
                          $ctrl.compactTaskViewConfig.isSplashing = false;
                          $ctrl.cacheTasks[$ctrl.direction] = {};
                          paginationConfig(false);
                        });
                    } else {
                      $ctrl.cacheTasks[$ctrl.direction] = {};
                      paginationConfig(false, 4);
                    }
                  } else {
                    paginationConfig(false, 5);
                  }
                }
              }
              $timeout.cancel(scrollPageFetchTimeout);
            }, (isFirstLoad) ? 0 : 1000);
          }

          function resetTaskList() {
            actualTasks = [];
            $ctrl.displayedTasks = [];
            $ctrl.metadata = {
              previous: {},
              next: {}
            };
            $ctrl.cacheTasks = {
              previous: {},
              next: {}
            };
            $ctrl.isVisibleCreateTask = !$ctrl.process ? isVisibleCreateTask() : false;
            $ctrl.compactTaskViewConfig.canLoadPreviousPage = !$ctrl.process ? false : angular.noop();
            $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.isPageFetched = true;
          }

          /**
           * @ngdoc method
           * @name paginationConfig
           *
           * @param {*} isFirstLoad
           *
           * @description
           * pagination configuration
           */
          function paginationConfig(isFirstLoad) {
            var pagination = _.get($ctrl.metadata[$ctrl.direction], 'pagination', { current_page: 0, total_pages: 1 });
            if (pagination.current_page === 0 && !$ctrl.process) {
              isFirstLoad = true;
              resetTaskList();
            }
            var isTaskCanBeLoad = $ctrl.process ? true : checkIfTaskCanBeLoaded($ctrl.direction, pagination);
            if (isTaskCanBeLoad) {
              if (!$ctrl.tasksFiltersConfig) {
                $ctrl.tasksFiltersConfig = {};
              }
              if (!$ctrl.taskRootConfig) {
                $ctrl.taskRootConfig = {};
              }
              $ctrl.taskRootConfig.activeTask = _.get($state.params, 'activeTask', '');
              var params = $ctrl.process ? getProcessFilter(pagination) :
                TasksFilterService.getTasksRequestParams($ctrl.tasksFiltersConfig, pagination, $ctrl.isGuest);
              if (_.get($ctrl.tasksFiltersConfig, 'group')) {
                params.groups = _.get($ctrl.tasksFiltersConfig, 'group');
              }
              if (_.get($ctrl.tasksFiltersConfig, 'role')) {
                params.roles = _.get($ctrl.tasksFiltersConfig, 'role');
              }
              if ($ctrl.taskRootConfig.activeTask && isFirstLoad) {
                var isActiveTaskInList = getActiveTask();
                params.currentTask = !isActiveTaskInList ? _.get($ctrl.taskRootConfig, 'activeTask') : void 0;
                params.replace_page = !isActiveTaskInList ? (params.currentTask ? 1 : 0) : void 0;
              } else {
                params.currentTask = void 0;
                params.replace_page = void 0;
              }
              if ($ctrl.compactTaskViewConfig.canLoadPreviousPage) {
                if ($ctrl.direction === 'previous') {
                  params.page = $ctrl.metadata.previous.pagination.current_page > 1
                    ? $ctrl.metadata.previous.pagination.current_page - 1 : 1;
                } else {
                  params.page = $ctrl.metadata.next.pagination.current_page < $ctrl.metadata.next.pagination.total_pages
                    ? $ctrl.metadata.next.pagination.current_page + 1 : $ctrl.metadata.next.pagination.total_pages;
                }
              }
              if (initialPageLoad == params.page) {
                params.page++;
                $ctrl.metadata.next.pagination.current_page++;
              }
              if ($ctrl.direction === 'previous') {
                params.page < 1 ? pendingInfiniteScroll() : getTasks(params);
              } else {
                params.page > pagination.total_pages ? pendingInfiniteScroll() : getTasks(params);
              }
            } else {
              pendingInfiniteScroll();
            }
          }

          function checkIfTaskCanBeLoaded(direction, pagination) {
            var result = false;
            if (direction === 'previous') {
              result = pagination.current_page > 1;
            } else {
              result = pagination.current_page !== pagination.total_pages
                && !((typeof $ctrl.metadata[direction].visible_tasks_total !== 'undefined')
                  && $ctrl.metadata[direction].visible_tasks_total === 0);
            }
            return result;
          }

          function getActiveTask() {
            return _.find(actualTasks, { id: $ctrl.taskRootConfig.activeTask });
          }

          /**
           * @ngdoc method
           * @name getProcessFilter
           * @param {*} pagination
           *
           * @description
           * get request parameters of process
           */
          function getProcessFilter(pagination) {
            var withStr = 'run,threads_count,step,tags,folders,threads,threads.reactions,form_fields',
              params = {
                per_page: _.get(pagination, 'per_page', 20),
                page: _.get(pagination, 'current_page', 0) + 1,
                with: withStr,
                sort: TASKORDER.POSITION
              };
            angular.extend(params, { with: withStr + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher') });
            if ($ctrl.stage) {
              angular.extend(params, { stage: $ctrl.stage.id });
            }
            if ($ctrl.unmappedStage && !($ctrl.isGuest || $ctrl.isPublicProcess)) {
              angular.extend(params, { without_stage: true });
            }
            return $ctrl.isPublicProcess ? angular.extend(params, {
              runId: $ctrl.process.id
            }) : angular.extend(params, {
              action: "runs",
              action_id: $ctrl.process.id
            });
          }

          /**
           * @ngdoc method
           * @name onPaneClose
           * @param {*} e
           *
           * @description
           * on right pane close handler
           */
          function onPaneClose(e) {
            e.stopPropagation();
            clearSelection();
            $ctrl.selectedItemSwitched = false;
          }

          /**
           * @ngdoc method
           * @name playSoundWhenTaskStatusChanged
           * @param {*} act
           *
           * @description
           * play task sound when status changed
           */
          function playSoundWhenTaskStatusChanged(act) {
            if ($ctrl.playSoundOnStatusChanged === 'yes') {
              SoundService[act]();
            }
          }

          /**
           * @ngdoc method
           * @name checkDependentTask
           * @param {*} task
           *
           * @description
           * checking dependent/rules task after task completed
           */
          function checkDependentTask(task) {
            var tasksToInsert = [];
            _.forOwn(task.tasks_changed_by_rules, function (value, key) {
              var dependentTask = _.find(actualTasks, { id: key });
              if (dependentTask) {
                angular.extend(dependentTask, {
                  status: value.status,
                  is_completable: true,
                  completed_at: DateUtils.toLocal(task.completed_at).format()
                });
                if (!$ctrl.isGuest) { // Currently enabling only for members. as the threads don't update for guests. When that changes, the resources (API call) need to be separate for member / guest
                  CompactTaskService.getSingleTask({
                    run_id: $ctrl.process ? _.get($ctrl.process, 'id') : void 0,
                    task_id: key,
                    with: 'threads,threads_count,folders',
                    skipNotFound: true
                  }).then(function (res) {
                    angular.extend(dependentTask, {
                      threads: _.get(res, 'data.threads', []),
                      threads_count: _.get(res, 'data.threads_count'),
                      deadline: _.get(res, 'data.deadline'),
                      deadline_unformatted: _.get(res, 'data.deadline_unformatted'),
                      owners: _.get(res, 'data.owners'),
                    });
                    setVisibleTasks();
                  });
                }
              } else {
                if ($ctrl.tasksFiltersConfig && $ctrl.tasksFiltersConfig.areTasksFiltersReady) {
                  var currentUser = $ctrl.isGuest ? _.get($rootScope.identity, 'guest.email', '') : _.get($rootScope.identity, 'id');
                  if (TasksService.isTaskChangedByRulesVisibleAtAllTasks($ctrl.tasksFiltersConfig, value, currentUser, $ctrl.orgGroups || [])) {
                    tasksToInsert.push(value);
                  }
                } else {
                  tasksToInsert.push(value);
                }
              }
            });
            if (tasksToInsert.length) {
              tasksToInsert = _.sortBy(tasksToInsert, 'position');
              var currentTaskIdx = _.findIndex(actualTasks, { id: task.id }), withStr = 'run,threads_count,step,tags,folders' + ($ctrl.isGuest ? ',guest_watchers.watcher' : ',member_watchers.watcher');
              _.forEach(tasksToInsert, function (insertItem, i) {
                var params;
                if (!$ctrl.isGuest) {
                  params = {
                    run_id: $ctrl.process ? _.get($ctrl.process, 'id') : void 0,
                    task_id: insertItem.id,
                    with: withStr,
                    skipNotFound: true
                  };
                } else {
                  params = {
                    id: insertItem.id,
                    with: withStr,
                    skipNotFound: true
                  };
                }
                if ($rootScope.userState === USER_STATE.MEMBER) {
                  if ($ctrl.stage) {
                    params.stage = $ctrl.stage.id;
                  }
                  if ($ctrl.unmappedStage && !($ctrl.isGuest || $ctrl.isPublicProcess)) {
                    params.without_stage = true;
                  }
                }
                var resource = !$ctrl.isGuest ? CompactTaskService.getSingleTask(params) : TasksService.getGuestTask(params);
                resource.then(function (res) {
                  angular.extend(insertItem, _.get(res, 'data', {}));
                  if (_.get(insertItem, 'stage_id', '').toString() == _.get($ctrl.stage, 'id', '').toString()) {
                    actualTasks.splice(currentTaskIdx + i + 1, 0, insertItem);
                  }
                });
              });
            }
            filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
          }

          /**
           * @ngdoc method
           * @private
           * @name postTaskCompleted
           * @param {*} task
           *
           * @description
           * post status completed action
           */
          function postTaskCompleted(task) {
            playSoundWhenTaskStatusChanged('onComplete');
            $rootScope.$emit('CHECK_DEPENDENT_TASK', { task: task })
            if ($ctrl.onTaskComplete) {
              $ctrl.onTaskComplete({
                actualTasks: $ctrl.displayedTasks
              });
            }
            getKoFieldAssigneeTasks();
          }

          function reloadProcess() {
            resetTaskList();
            firstLoadTasks();
          }

          /**
           * @ngdoc method
           * @name postTaskInComplete
           */
          function postTaskInComplete(task) {
            playSoundWhenTaskStatusChanged('onReOpen');
            $rootScope.$emit('CHECK_DEPENDENT_TASK', { task: task })
            if ($ctrl.onTaskComplete) {
              $ctrl.onTaskComplete({
                actualTasks: $ctrl.displayedTasks
              });
            }
          }

          /**
           * @ngdoc method
           * @name splashingActiveTask
           *
           * @description
           * check for active task
           */
          function splashingActiveTask() {
            var defer = $q.defer();
            var selectedIndex = _.findIndex($ctrl.displayedTasks, { id: $ctrl.taskRootConfig.activeTask });
            if ($ctrl.taskRootConfig.activeTask && selectedIndex === -1) {
              if (selectedIndex !== -1) {
                if ($ctrl.stage && $ctrl.displayedTasks[selectedIndex] && ($ctrl.stage.id !== $ctrl.displayedTasks[selectedIndex].stage_id)) {
                  return;
                }
                scrollToActiveTask(selectedIndex);
                $ctrl.compactTaskViewConfig.isSplashing = false;
              } else {
                if (!$ctrl.activeTask) {
                  CompactTaskService.getSingleTask({
                    run_id: $ctrl.process ? $ctrl.process.id : void 0,
                    task_id: $ctrl.taskRootConfig.activeTask,
                    with: 'run,step,threads',
                    skipNotFound: true
                  }).then(function (res) {
                    $ctrl.compactTaskViewConfig.isSplashing = false;
                    if ($ctrl.stage && (res.data.stage_id === $ctrl.stage.id)) {
                      if (Helper.isObjectEmpty($ctrl.selectedIndex)) {
                        $ctrl.splashItem = {
                          task: res.data,
                          index: -1,
                          isGuest: $ctrl.isGuest,
                          viewerId: $ctrl.viewerId,
                          usersInOrg: $ctrl.usersInOrg,
                          process: $ctrl.process || _.get(res.run, 'data')
                        };
                        $rootScope.$emit('RIGHT_PANE:OPEN', {
                          item: {
                            task: res.data,
                            index: -1
                          },
                          customClass: ((res.data.status === 'completed' && res.data.task_type === 'approval' && !res.data.is_approved) ? " rejected-task " : "")
                        });
                      }
                    }
                    defer.resolve();
                  }, function () {
                    defer.reject();
                  });
                } else {
                  $ctrl.compactTaskViewConfig.isSplashing = false;
                  if ($ctrl.stage && ($ctrl.activeTask.stage_id === $ctrl.stage.id)) {
                    if (Helper.isObjectEmpty($ctrl.selectedIndex)) {
                      $ctrl.splashItem = {
                        task: $ctrl.activeTask,
                        index: -1,
                        isGuest: $ctrl.isGuest,
                        viewerId: $ctrl.viewerId,
                        usersInOrg: $ctrl.usersInOrg,
                        process: $ctrl.process || _.get(res.run, 'data')
                      };
                      $rootScope.$emit('RIGHT_PANE:OPEN', {
                        item: {
                          task: res.data,
                          index: -1
                        },
                        customClass: ((res.data.status === 'completed' && res.data.task_type === 'approval' && !res.data.is_approved) ? " rejected-task " : "")
                      });
                    }
                  }
                  defer.resolve();
                }
              }
            } else {
              if ($ctrl.splashItem) {
                scrollToActiveTask(selectedIndex);
              }
              $ctrl.compactTaskViewConfig.isSplashing = false;
              defer.resolve();
            }
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name scrollToActiveTask
           * @param {*} index
           *
           * @description
           * scroll to active task
           */
          function scrollToActiveTask(index) {
            $rootScope.$emit('STAGE:EXPAND_ELEMENT', { stage: $ctrl.stage, task: $ctrl.displayedTasks[index] });
            $timeout(function () {
              if ($window.innerWidth >= 1280) {
                DOMService.centerObjectToView('.compact-item-selected', {
                  behavior: "smooth",
                  block: "center"
                });
              }
              if (!$ctrl.selectedItem) {
                $ctrl.selectedItem = {};
              }
              $ctrl.selectedItem.task = $ctrl.displayedTasks[index];
              $ctrl.selectedIndex = index;
              $ctrl.splashItem = void 0;
            }, 1000);
          }


          /**
           *
           * @ngdoc method
           * @name clearSelection
           *
           * @description
           * clear selected task
           */
          function clearSelection() {
            $ctrl.selectedItem = void 0;
            $ctrl.compactTaskViewConfig.rightSection.isShow = false;
          }

          /**
           * @ngdoc method
           * @name firstLoadTasks
           *
           * @description
           * on data first load handler
           */
          function firstLoadTasks() {
            $ctrl.process ? $ctrl.viewerId = _.get($rootScope.identity, 'id') : angular.noop();
            if (!$ctrl.compactTaskViewConfig.isFirstLoad) {
              clearSelection();
            }
            (!$ctrl.viewerId && !$ctrl.isGuest) ? $ctrl.viewerId = _.get($rootScope.identity, 'id') : angular.noop();
            $ctrl.compactTaskViewConfig.isFirstLoad = $ctrl.isPageFetched = true;
            DOMService.scrollViewToTop($ctrl.compactTaskViewConfig.mainSection);
            $scope.$applyAsync(onPageFetch(true));
          }

          function isVisibleCreateTask() {
            var tasksFiltersConfig = $ctrl.tasksFiltersConfig, defaultFilter = TasksFilterService.getDefaultFilters();
            return tasksFiltersConfig.sortBy !== defaultFilter.sortBy ||
              tasksFiltersConfig.process !== defaultFilter.process ||
              tasksFiltersConfig.states !== defaultFilter.states;
          }

          /** @ngdoc method
           * @name onTooltipClose
           * @description
           * handle onboarding tooltip
           * @param {*} task
           */
          function onTooltipClose(task) {
            var currentIndex = _.findIndex(angular.copy($ctrl.displayedTasks), { 'id': task.id });
            if (ProcessService.isForceOnboarding('HasFirstTaskTooltip') || ProcessService.isForceOnboarding('HasSecondTaskTooltip')) {
              _.get(task, 'showTooltip') ? delete $ctrl.displayedTasks[currentIndex].showTooltip : angular.noop();
              if (currentIndex === 0) {
                ProcessService.setForceOnboardingFlowData({ key: 'HasFirstTaskTooltip', value: 'yes' });
                $ctrl.displayedTasks.length > 1 ? $ctrl.displayedTasks[currentIndex + 1].showTooltip = true : angular.noop();
              }
              if (currentIndex === 1) {
                ProcessService.setForceOnboardingFlowData({ key: 'HasSecondTaskTooltip', value: 'yes' });
              }
            }
          }

          /**
           * @ngdoc method
           * @name getSortableOptions
           *
           * @description
           * get sortable options
           */
          function getSortableOptions() {
            return {
              handle: '.task-number',
              connectWith: '.compact-task-list',
              scroll: true,
              axis: 'y',
              start: startHandler,
              stop: sortHandler,
              sort: dragHandler,
              receive: receiveHandler,
              animation: 500,
              disabled: !Helper.checkAccessAuthority(false) || $ctrl.isPublicProcess,
              scrollSpeed: 25,
              forceFallback: true,
              longTouch: true
            };
          }

          /**
          * @ngdoc method
          * @name dragHandler
          * @param {*} e
          *
          * @description
          * drag handler for sortable list options
          */
          function dragHandler(e) {
            $ctrl.pageY < e.pageY ? $(this).sortable("option", "scrollSensitivity", 10) : $(this).sortable("option", "scrollSensitivity", 208);
            $ctrl.pageY = e.pageY;
          }

          /**
           * @ngdoc method
           * @name startHandler
           * @param {*} e
           * @param {*} ui
           *
           * @description
           * start handler for sortable list options
           */
          function startHandler(e, ui) {
            $ctrl.pageY = e.pageY;
            if (firstSort) {
              $(this).sortable("refreshPositions");
              firstSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
            ui.placeholder.width(ui.item.find('.compact-task-context').width());
          }

          /**
           * @name sortHandler
           * @param {*} e
           * @param {*} ui
           *
           * @description
           * sort handler for sortable list options
           */
          function sortHandler(e, ui) {
            if (ui.item.sortable.dropindex > -1 && ui.item.sortable.dropindex < $ctrl.displayedTasks.length) {
              var preTask = $ctrl.displayedTasks[ui.item.sortable.dropindex - 1],
                task = $ctrl.displayedTasks[ui.item.sortable.dropindex],
                positionToMove = _.get(preTask, 'position', 0);
              var prevPosition = task.position;
              if ($ctrl.displayedTasks[ui.item.sortable.dropindex - 1]) {
                task.position = $ctrl.displayedTasks[ui.item.sortable.dropindex - 1].position + 1;
              } else {
                task.position = positionToMove;
              }
              if ($ctrl.selectedItem && $ctrl.selectedItem.task) {
                $ctrl.selectedIndex = _.findIndex($ctrl.displayedTasks, { id: _.get($ctrl.selectedItem, 'task.id') });
              }
              $ctrl.isLoading = true;
              blockUI.start();
              CompactTaskService
                .reOrderTask({
                  id: task.id,
                  action: "runs",
                  action_id: task.run_id,
                  sub_action: 'reorder'
                }, {
                  position: task.position
                })
                .then(function (res) {
                  var editedTask = _.find($ctrl.displayedTasks, { id: res.data.id });
                  editedTask.position = res.data.position;
                  repositioningTask(prevPosition - 1, positionToMove);
                  $ctrl.isLoading = false;
                  blockUI.stop();
                }, function () {
                  $ctrl.isLoading = false;
                  blockUI.stop();
                });
            }
          }

          function receiveHandler(e, ui) {
            var position = ui.item.sortable.dropindex;
            $timeout(function () {
              var task = _.find($ctrl.displayedTasks, { id: ui.item[0].dataset.taskId });
              linkMilestone($ctrl.stage, task, position);
            }, 0);
          }

          function linkMilestone(milestone, task, pos) {
            var process = $ctrl.process || taskProcess;
            if (!milestone) {
              task.stage_id = null;
              MilestoneService.unLinkTaskToMilestone(process.id, task.id)
                .then(function (res) {
                  if ($ctrl.process) {
                    Helper.showChangesSavedGrowl();
                  }
                });
            } else {
              task.stage_id = milestone.id;
              MilestoneService.linkTaskToMilestone(process.id, task.id, milestone.id)
                .then(function (res) {
                  if ($ctrl.process) {
                    Helper.showChangesSavedGrowl();
                  }
                });
            }
            $ctrl.milestonePopoverIsOpen = false;
          }

          function repositioningTask(prevPosition, positionToMove) {
            if (prevPosition < positionToMove) {
              for (var i = prevPosition; i < positionToMove; i++) {
                if ($ctrl.displayedTasks[i]) {
                  $ctrl.displayedTasks[i].position = i + 1;
                }
              }
            } else {
              for (var i = positionToMove; i < $ctrl.displayedTasks.length; i++) {
                if ($ctrl.displayedTasks[i]) {
                  $ctrl.displayedTasks[i].position = i + 1;
                }
              }
            }
            $rootScope.$emit('RUN:TASK_UPDATED');
          }

          // event for task completed handler
          onTaskCompletedHandler = $rootScope.$on('COMPACT_TASK:COMPLETED', function (e, data) {
            var taskChanged = _.find(actualTasks, { id: _.get(data, 'task.id') });
            if (!taskChanged) {
              return;
            }
            angular.extend(taskChanged, {
              status: data.task.status,
              status_label: data.task.status_label,
              completed_at: DateUtils.toLocal(data.task.completed_at),
              completer_id: data.task.completer_id,
              is_completable: data.task.is_completable,
              completer_guest: data.task.completer_guest,
              is_approved: data.task.is_approved
            });
            if (taskChanged.status === 'completed') {
              if ($ctrl.process && data.task.tasks_within_process) {
                playSoundWhenTaskStatusChanged('onComplete');
                if (!$ctrl.stage) {
                  initialPageLoad = null;
                  $ctrl.metadata.next.pagination = { current_page: 0, total_pages: 1 };
                  $scope.$applyAsync(onPageFetch(true));
                }
              } else {
                postTaskCompleted(data.task);
                closeAndOpenNextTask(e, data.task);
              }
            } else {
              postTaskInComplete(data.task);
            }
            if ($ctrl.forceOnboarding) {
              onTooltipClose(taskChanged);
            }
            if (data.isRejectedTask || !Helper.isObjectEmpty(data.autoLaunchProcess) || data.task.task_type === 'email') {
              getLatestTaskData(void 0, taskChanged, true);
            }
          });

          /**
           * @name getLatestTaskData
           * @description Get the latest task data
           * @param {*} updatedTask
           * @param {*} currentTask
           * @param {*} fetchWithParams
           */
          function getLatestTaskData(updatedTask, currentTask, fetchWithParams) {
            var params = {};
            if (fetchWithParams) {
              angular.extend(params, { with: 'threads' });
            }
            if ($rootScope.userState === USER_STATE.MEMBER) {
              if ($ctrl.stage) {
                params.stage = $ctrl.stage.id;
              }
              if ($ctrl.unmappedStage && !($ctrl.isGuest || $ctrl.isPublicProcess)) {
                params.without_stage = true;
              }
            }
            var resource = $ctrl.isGuest
              ? TasksService.getGuestTask(angular.extend({
                action_id: currentTask.id
              }, params)) : TasksService.getTask(angular.extend({
                action_id: currentTask.is_oneoff_task ? void 0 : currentTask.run_id,
                id: currentTask.id
              }, params));
            resource.then(function (response) {
              angular.extend(currentTask, response.data);
              $rootScope.$emit('TASKS_DATA:UPDATED', { process: $ctrl.process });
            }, function () { });
          }

          /**
           * @ngdoc method
           * @name closeAndOpenNextTask
           * @param {*} e
           * @param {*} currentTask
           *
           * @description
           * open and close next task
           */
          function closeAndOpenNextTask(e, currentTask) {
            var currentTaskIndex = _.findIndex($ctrl.displayedTasks, { id: currentTask.id });
            if (currentTaskIndex < $ctrl.displayedTasks.length - 1) {
              var tasks = _.filter($ctrl.displayedTasks, function (task) {
                return task.status !== 'auto-skipped';
              });
              var nextTask = tasks[currentTaskIndex + 1];
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  task: nextTask,
                  index: currentTaskIndex + 1
                }
              });
            }
          }

          /**
           * capture event STANDALONE_TASK:DELETE
           * and unregister when component is destroyed
           * @see onDestroy
           */
          unRegisterDeleteTaskEventHandler = $rootScope.$on('STANDALONE_TASK:DELETE', function (event, data) {
            if (data.index >= 0) {
              mappingPositionsOnRemoved(data.id, data.index);
              filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
              $ctrl.taskLength = $ctrl.taskLength - 1;
              $rootScope.$emit('RIGHT_PANE:CLOSE', {});
            }
          });

          /**
           * capture event STANDALONE_TASK:RESTORE
           * and unregister when component is restore
           * @see onDestroy
           */
          unRegisterRestoreTaskEventHandler = $rootScope.$on('STANDALONE_TASK:RESTORE', function (event, data) {
            if (data.index >= 0) {
              $ctrl.taskLength = $ctrl.taskLength + 1;
              $ctrl.displayedTasks.splice(data.index, 0, data.task);
            }
          });

          unRegisterAddTaskEventHandler = $rootScope.$on('STANDALONE_TASK:CREATED', function (e, data) {
            if (!data.unmapped) {
              if (data.stage.id !== $ctrl.stage.id) {
                return;
              }
              MilestoneService.linkTaskToMilestone($ctrl.process.id, data.task.id, data.stage.id)
                .then(function (res) {
                  $ctrl.metadata.next.pagination.total++;
                  standalondTaskCreatedHandler(data);
                });
            } else {
              standalondTaskCreatedHandler(data);
            }
          });

          function standalondTaskCreatedHandler(data) {
            if (data.task) {
              if ($ctrl.stage) {
                if (!data.stage || (data.stage.id !== $ctrl.stage.id)) {
                  return;
                }
              }
              if (!$ctrl.displayedTasks) {
                $ctrl.displayedTasks = [];
              }
              if ($ctrl.process) {
                $ctrl.displayedTasks.push(data.task);
                actualTasks.push(data.task);
              } else {
                $ctrl.displayedTasks = _.concat(data.task, $ctrl.displayedTasks);
                actualTasks = _.concat(data.task, actualTasks);
              }
              _.forEach($ctrl.displayedTasks, function (value, index) {
                value.position = index + 1;
              });
              getLatestTaskData(void 0, data.task, true);
              $ctrl.taskLength = $ctrl.taskLength + 1;
            }
            if (data.taskOpen) {
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  task: data.task,
                  index: parseInt(_.get(data, 'task.position', 1)) - 1,
                  editMode: false
                },
                rightPaneCustomClass: 'compact-container-task-detail'
              });
            }
            filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
            getKoFieldAssigneeTasks();
          }

          function loadMore() {
            $scope.$applyAsync(onPageFetch(false, _.get($ctrl.metadata, 'next.pagination.current_page') === _.get($ctrl.metadata, 'next.pagination.total_pages') ? !!$ctrl.compactTaskViewConfig.canLoadPreviousPage : false));
          }

          // event handler when task fetch on next state
          onFetchNextHandler = $rootScope.$on('COMPACT_TASK:FETCH_NEXT', function (e, data) {
            if ($ctrl.stage) {
              if (data.stage) {
                if ($ctrl.stage.id === data.stage.id) {
                  loadMore();
                }
              }
            } else {
              loadMore();
            }
          });

          // event handler when task fetch on next state
          onFetchPreviousHandler = $rootScope.$on('COMPACT_TASK:FETCH_PREVIOUS', function (e, data) {
            $scope.$applyAsync(onPageFetch(false, true));
          });

          // selected task threads data watcher
          selectedItemThreadsWatcher = $scope.$watch('$ctrl.selectedItem.task.threads.data', function (data) {
            if ($ctrl.selectedItem && $ctrl.selectedItem.task) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
            }
            data ? $ctrl.selectedItem.task.threads_count = data.length
              : angular.noop();
          }, true);

          // watch for threads data edited
          unregisteredIssueReportedEventHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            angular.extend($ctrl.selectedItem.task, { hasIssues: true });
            var task = _.get($ctrl.selectedItem, 'task', {});
            if (task && data.issue.task_id === task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
            }
          });

          // watch for threads data edited
          unregisteredIssueResolvedEventHandler = $rootScope.$on('ISSUE:RESOLVED', function (e, data) {
            var task = _.get($ctrl.selectedItem.task, {});
            _.extend($ctrl.selectedItem.task, { hasIssues: false });
            if (task && data.issue.task_id === $ctrl.selectedItem.task.id) {
              $ctrl.isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
            }
          });

          // primary assignee config data watcher
          unRegisterConfigWatcher = $scope.$watch('$ctrl.tasksFiltersConfig.assignee', function (value) {
            if (value) {
              $ctrl.viewerId = (value === 'by-me' || value === 'at-mentioned' || value === 'unassigned') ? void 0 : value;
            }
          }, true);

          unRegisterHiddenTaskConfig = $scope.$watch('$ctrl.taskRootConfig.showHiddenTasks', function (value) {
            if (_.get($ctrl.process, 'tasks.data', []).length > 0 && !_.find(_.get($ctrl.process, 'tasks.data', []), { 'id': _.get($ctrl.selectedItem, 'task.id') })) {
              $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
            }
            if (actualTasks && typeof value !== 'undefined') {
              filterDisplayedTask(value, $ctrl.process ? 'position' : void 0);
            }
          });

          /**
           * event handler Update task title / summary variables when a field is updated. Handles only invisible / hidden tasks (where compactTask component is not active), visible tasks updating handled at compactTask component.
           * @type {*|(function())}
           */
          unregisterUpdateCaptureListener = $rootScope.$on('CAPTURE:UPDATE', function (event, data) {
            var updatedTask = data.updatedTask, updatedCapture = data.updatedCapture;
            if (data.updatedTask === 'ko-form') {
              _.map(actualTasks, function (task) {
                task.summaryNeedToBeUpdated = true;
              });
              filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
            } else if (!$ctrl.taskRootConfig.showHiddenTasks) {
              var tasksToBeUpdated = _.filter(actualTasks, function (task) {
                return (task.status === 'auto-skipped') && CompactTaskService.isTaskToBeUpdatedOnCaptureUpdate(task, updatedTask, updatedCapture, $ctrl.isGuest);
              });
              _.forEach(tasksToBeUpdated, function (task) {
                getLatestTaskData(updatedTask, task, false);
              });
              var cachedTasks = _.get($ctrl.cacheTasks[$ctrl.direction], 'data', []);
              if (cachedTasks.length) {
                _.forEach(cachedTasks, function (task) {
                  if (CompactTaskService.isTaskToBeUpdatedOnCaptureUpdate(task, updatedTask, updatedCapture, $ctrl.isGuest)) {
                    getLatestTaskData(updatedTask, task, false);
                  }
                });
              }
            }
          });

          /**
           * event handler Update task title / summary variables when a field is updated. Handles only invisible / hidden tasks (where compactTask component is not active), visible tasks updating handled at compactTask component.
           * @type {*|(function())}
           */
          unregisterUpdateCaptureListener = $rootScope.$on('COMPACT_TASK:UPDATE_DATA', function (event, eventData) {
            if ($ctrl.stage && (data.updatedTask.stage_id !== $ctrl.stage.id)) {
              return;
            }
            if (!$ctrl.taskRootConfig.showHiddenTasks) {
              var tasksToBeUpdated = _.filter(actualTasks, function (task) {
                return (task.status === 'auto-skipped') && CompactTaskService.isTaskToBeUpdatedOnDeadlineUpdate(task, eventData);
              });
              _.forEach(tasksToBeUpdated, function (task) {
                getLatestTaskData(eventData.updatedTask, task, eventData.fetchWithParams);
              });
              var cachedTasks = _.get($ctrl.cacheTasks[$ctrl.direction], 'data', []);
              if (cachedTasks.length) {
                _.forEach(cachedTasks, function (task) {
                  if (CompactTaskService.isTaskToBeUpdatedOnDeadlineUpdate(task, eventData)) {
                    getLatestTaskData(eventData.updatedTask, task, eventData.fetchWithParams);
                  }
                });
              }
            }
          });

          unregisteredUnlinkToProcess = $rootScope.$on('COMPACT_TASK:UNLINK_FROM_PROCESS', function (e, data) {
            if ($ctrl.process) {
              if (data.stageId != _.get($ctrl.stage, 'id', null)) {
                return;
              }
              $ctrl.taskLength = $ctrl.taskLength--;
              $ctrl.metadata.next.pagination.total--;
              mappingPositionsOnRemoved(data.taskId, data.index);
              filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, $ctrl.process ? 'position' : void 0);
              $rootScope.$emit('RIGHT_PANE:CLOSE', {});
            }
          });

          unregisteredTaskUpdateHandler = $rootScope.$on('COMPACT_TASK:UPDATE', function (e, data) {
            var task = _.find(actualTasks, { id: data.task.id });
            if (task) {
              angular.extend(task, data.task);
            }
            getKoFieldAssigneeTasks();
          });

          function mappingPositionsOnRemoved(taskId, index) {
            var actualTaskIndex = _.findIndex(actualTasks, { id: taskId });
            actualTasks.splice(actualTaskIndex, 1);
            _.forEach(actualTasks, function (value, index) {
              value.position = index + 1;
            });
            $ctrl.displayedTasks.splice(index, 1);
          }

          function filterDisplayedTask(showHiddenTasks, orderBy) {
            $ctrl.displayedTasks = $filter('activeTask')(actualTasks, {
              showHiddenTasks: showHiddenTasks,
              orderBy: orderBy
            });
            $ctrl.totalTask = actualTasks.length;
            setVisibleTasks(showHiddenTasks);
          }

          function getGuestOrganization() {
            var defer = $q.defer();
            CompactTaskService.getGuestOrganization()
              .then(function (res) {
                defer.resolve(res);
              }, function (err) {
                defer.resolve(err);
              });
            return defer.promise;
          }

          function openInModal(e, task) {
            $uibModal.open({
              component: 'singleTaskModal',
              windowClass: 'single-task-modal',
              backdrop: 'static',
              resolve: {
                saveSelectedTask: function () {
                  return $ctrl.saveSelectedTask;
                },
                discardSelectedTask: function () {
                  return $ctrl.qDiscardTaskCallback;
                },
                selectedTask: function () {
                  return task;
                },
                process: function () {
                  return $ctrl.process;
                },
                orgAllUsers: function () {
                  return $ctrl.orgAllUsers;
                },
                usersInOrg: function () {
                  return $ctrl.usersInOrg;
                },
                selectedTaskIndex: function () {
                  var visibleTasks = _.filter($ctrl.displayedTasks, function (task) {
                    return task.status !== 'auto-skipped';
                  });
                  return visibleTasks ? _.findIndex(visibleTasks, { id: task.id }) : -1;
                },
                orgGroups: function () {
                  return $ctrl.orgGroups;
                }
              }
            });
          }

          function setVisibleTasks(showHiddenTasks) {
            _.get($ctrl.metadata, '[' + $ctrl.direction + ']', {}).visible_tasks_total = _.filter(actualTasks, function (task) {
              return showHiddenTasks ? true : task.status !== 'auto-skipped';
            }).length;
          }

          $rootScope.$on('TASK:SUMMARY_UPDATED', function (e, data) {
            _.map(actualTasks, function (task) {
              if (task.id === data.task.id) {
                task.summaryNeedToBeUpdated = void 0;
              }
            });
            filterDisplayedTask(_.get($ctrl.taskRootConfig, 'showHiddenTasks') || false, $ctrl.process ? 'position' : void 0);
          });

          $rootScope.$on('COMPACT_TASK:BULK_ASSIGN_UPDATE', function (e, data) {
            if (data.type === 'power-job-change') {
              _.forEach(data.tasks, function (task) {
                var index = _.findIndex(actualTasks, { id: task.subject_id });
                if (index > -1) {
                  var role = [];
                  _.each(task.org_role_id, function(i) {
                    role.push({ org_role_id: i, subject_id: task.subject_id, subject_type: 'Task' });
                  });
                  actualTasks[index]['roles'] = { data: role };
                }
              });
            }
            
            if (data.type === 'power-assigner-and-job-change') {
              _.forEach(data.tasks.job, function (task) {
                if (task) {
                  var index = _.findIndex(actualTasks, { id: task.id });
                  if (index > -1) {
                    var role = [];
                    _.each(task.org_role_id, function(i) {
                      role.push({ org_role_id: i, subject_id: task.subject_id, subject_type: 'Task' });
                    });
                    actualTasks[index]['roles'] = { data: role };
                  }
                }
              });
              _.forEach(data.tasks.assigner, function (task) {
                var index = _.findIndex(actualTasks, { id: task.id });
                if (index > -1) {
                  actualTasks[index]['owners'] = task.owners;
                }
              });
            }
            if (data.type === 'power-assigner' || data.type === 'power-deadline') {
              var dependentTasks = _.filter(data.tasks, { has_deadline_dependent_child_tasks: true });
              if (data.type === 'power-deadline' && dependentTasks.length > 0) {
                $rootScope.$applyAsync(onPageFetch(true));
              } else {
                _.forEach(data.tasks, function (task) {
                  var index = _.findIndex(actualTasks, { id: task.id });
                  if (index > -1) {
                    if (data.type === 'power-assigner') {
                      actualTasks[index]['owners'] = task.owners;
                    }
                    if (data.type === 'power-deadline') {
                      actualTasks[index]['deadline_unformatted'] = task.deadline_unformatted;
                      actualTasks[index]['deadline'] = task.deadline;
                    }
                    if (task.threads) {
                      actualTasks[index]['threads'] = task.threads;
                      actualTasks[index]['threads_count'] = task.threads.length;
                    }
                  }
                });
              }
            }
          });

          postCommentUpdateHandler = $rootScope.$on('REOPEN_COMMENT:UPDATE', function (event, res) {
            var index = _.findIndex($ctrl.displayedTasks, { id: res.task_id });
            if (index > -1) {
              var threads = _.get($ctrl.displayedTasks[index], 'threads.data', []);
              threads.push(res);
            }
          });

          compactTaskCreatedHandler = $rootScope.$on('COMPACT_TASK:CREATED', function (e, data) {
            if (data.task) {
              angular.extend($state.params, { activeTask: data.task.id });
              if ($state.is('run.view')) {
                $ctrl.displayedTasks.push(data.task);
                actualTasks = _.uniqBy(_.concat(actualTasks, data.task), 'id');
              } else {
                reloadProcess();
              }
            }
          });

          koFieldAssigneeClickEventHandler = $rootScope.$on(
            'KO_FIELD_ASSIGNEE:CLICK',
            function (e, data) {
              $ctrl.standaloneModal($ctrl.process.id, data.field);
            }
          );

          taskUnLinkedWatcherHandler = $rootScope.$on(
            'STAGE:TASK_UNLINKED',
            function (e, data) {
              if ($ctrl.stage) {
                var taskIdx = _.findIndex($ctrl.displayedTasks, { id: data.task.id });
                if (taskIdx < 0) {
                  return;
                }
                $ctrl.displayedTasks.splice(taskIdx, 1);
                actualTasks = $ctrl.displayedTasks;
                filterDisplayedTask(_.get($ctrl.taskRootConfig, 'showHiddenTasks', false), data.task ? 'position' : void 0);
                getLatestTaskData(void 0, data.task, true);
                $ctrl.metadata.next.pagination.total = $ctrl.taskLength = $ctrl.taskLength - 1;
              } else {
                if (!$ctrl.displayedTasks) {
                  $ctrl.displayedTasks = [];
                }
                $ctrl.displayedTasks.splice(!data.task.position ? 0 : data.task.position - 1, 0, data.task);
                _.forEach($ctrl.displayedTasks, function (value, index) {
                  value.position = index + 1;
                });
                actualTasks = $ctrl.displayedTasks;
                filterDisplayedTask(_.get($ctrl.taskRootConfig, 'showHiddenTasks', false), data.task ? 'position' : void 0);
                getLatestTaskData(void 0, data.task, true);
                $ctrl.metadata.next.pagination.total = $ctrl.taskLength = $ctrl.taskLength - 1;
                $timeout(function () {
                  $rootScope.$emit('STAGE:EXPAND_ELEMENT', { task: data.task });
                }, 350);
              }
            }
          );

          taskLinkedWatcherHandler = $rootScope.$on(
            'STAGE:TASK_LINKED',
            function (e, data) {
              if ($ctrl.unmappedStage || !$ctrl.stage) {
                var taskIdx = _.findIndex($ctrl.displayedTasks, { id: data.task.id });
                if (taskIdx < 0) {
                  return;
                }
                $ctrl.displayedTasks.splice(taskIdx, 1);
                actualTasks = $ctrl.displayedTasks;
                filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, data.task ? 'position' : void 0);
                getLatestTaskData(void 0, data.task, true);
                $ctrl.metadata.next.pagination.total = $ctrl.taskLength = $ctrl.taskLength + 1;
              } else {
                if ($ctrl.stage.id === data.stage.id) {
                  if (!$ctrl.displayedTasks) {
                    $ctrl.displayedTasks = [];
                  }
                  $ctrl.displayedTasks.splice(0, 0, data.task);
                  actualTasks = $ctrl.displayedTasks;
                  filterDisplayedTask($ctrl.taskRootConfig.showHiddenTasks || false, data.task ? 'position' : void 0);
                  getLatestTaskData(void 0, data.task, true);
                  $ctrl.metadata.next.pagination.total = $ctrl.taskLength = $ctrl.taskLength + 1;
                  $timeout(function () {
                    $rootScope.$emit('STAGE:EXPAND_ELEMENT', { stage: $ctrl.stage, task: data.task });
                  }, 350);
                }
              }
            }
          );

          unregisterTaskLinkHandler = $rootScope.$on('COMPACT_TASK:TASK_LINKED', function (e, data) {
            if (data.task) {
              angular.extend(_.find($ctrl.displayedTasks, { id: data.task.id }), data.task);
            }
          });

          unregisterRightPaneOpenHandler = $rootScope.$on('RIGHT_PANE:OPEN', function () {
            $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT_LEFT_PANE;
          });

          unregisterRightPaneCloseHandler = $rootScope.$on('RIGHT_PANE:CLOSE', function () {
            if (!$ctrl.process) {
              $ctrl.tooltipLimit = COMMON.TITLE_TOOLTIP_LIMIT;
            }
          });

          processTasksLoadedHandler = $rootScope.$on('PROCESS_TASKS:FETCH_END', function () {
            if ($ctrl.koTaskOnly) {
              $ctrl.compactTaskViewConfig.isSplashing = $ctrl.compactTaskViewConfig.isFirstLoad = false;
            }
          })

          dependentTaskCheck = $rootScope.$on('CHECK_DEPENDENT_TASK', function (e, data) {
            checkDependentTask(data.task);
          })
        }
    });
})();