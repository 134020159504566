/**
 * @ngdoc Component
 * @name tallyfy.steps.component.renderDate
 * @module tallyfy.steps
 *
 * @description
 * renderDate
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.steps')
    .component('renderDate', {
      templateUrl: 'app/modules/steps/capture/fields/date/render.html',
      bindings: {
        field: '=',
        fieldValue: "<",
        isDisabled: '<',
        validationErrors: '<',
        isGuest: '<?',
        hideFieldLabel: '<?',
        hideNoInfoData: '<',
        translateEnable: '<?',
        asDefaultValue: '<',
        isNewField: '<'
      },
      require: {
        taskCtrl: '?^task',
        renderField: '^renderField'
      },
      controller:
        /*@ngInject*/
        function ($rootScope, TranslationService, $scope, $timeout, moment, FieldService, _, DateUtils, DATEFORMAT, OrganizationsService, $stateParams) {
          var $ctrl = this,
            updateTaskListener,
            updateTaskStatusListener;

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          $ctrl.validateMinMaxValue = validateMinMaxValue;

          /**
           * public properties
           */
          $ctrl.datePicker = {
            dateOptions: {
              dateDisabled: false,
              formatYear: 'yy',
              formatMonth: 'MMM',
              monthColumns: '4',
              maxDate: new Date(9999, 5, 22),
              startingDay: 1,
              showButtonBar: false,
              altInputFormats: ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'],
              showWeeks: false,
              ngModelOptions: {
                debounce: 100
              }
            },
            isOpened: false
          };
          $ctrl.splittedTime = {};
          $ctrl.hstep = 1;
          $ctrl.mstep = 1;

          /**
           * public methods
           */
          $ctrl.openDatePicker = openDatePicker;
          $ctrl.updateChange = updateChange;
          $ctrl.onTimeChange = onTimeChange;
          $ctrl.isValidMonth = isValidMonth;
          $ctrl.isValidDay = isValidDay;
          $ctrl.isValidYear = isValidYear;
          $ctrl.isValidDate = isValidDate;
          $ctrl.fieldFocus = fieldFocus;
          $ctrl.fieldBlur = fieldBlur;

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.translateEnable) {
              $ctrl.translateTo = TranslationService.getMyContentLanguage();
            }
            if ($ctrl.asDefaultValue) {
              $ctrl.currentDateAndTimeSelected = !$ctrl.field.default_value;
              $ctrl.fieldValue = $ctrl.field.default_value || 0;
              $ctrl.field.default_value = $ctrl.currentDateAndTimeSelected ? 0 : $ctrl.field.default_value;
              if (!_.get($ctrl.renderField, 'process.id')) {
                fieldBlur();
              }
            }
            var fieldValue = $ctrl.taskCtrl ? (_.get($ctrl.field, 'step_id') ? $ctrl.taskCtrl.task.taskdata[$ctrl.field.id] : $ctrl.fieldValue) : $ctrl.fieldValue;
            if (fieldValue) {
              updateDatePicker(fieldValue);
            } else {
              $ctrl.field.value = "";
            }
            $ctrl.isDisabled = ($ctrl.taskCtrl && $ctrl.taskCtrl.isCompleted) || ($ctrl.renderField && $ctrl.renderField.isDisabled);
            $ctrl.isReadonly = $ctrl.renderField && $ctrl.renderField.renderFormCtrl.isReadonly;
            $ctrl.processView = $ctrl.renderField && $ctrl.renderField.processView;
            var dateFormat = OrganizationsService.dateFormat();
            $ctrl.selectedDateFormat = _.get(dateFormat, 'dateFormat');
            $ctrl.sortDateFormat = $ctrl.field.collect_time ? (dateFormat ? dateFormat.format : DATEFORMAT.DEFAULT) : (dateFormat ? (dateFormat.dateFormat).toUpperCase() : DATEFORMAT.SHORT);
            if (_.get($ctrl.renderField, 'process.id')) {
              angular.extend($ctrl.datePicker.dateOptions, {
                maxDate: new Date().setDate(new Date().getDate() + 1095)
              });
            }
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           * @param {Object} bindings
           */
          function onChanges() { }
          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            $scope.$on('$destroy', updateTaskStatusListener);
            if (updateTaskListener) {
              $timeout.cancel(updateTaskListener);
            }
          }

          function openDatePicker() {
            $ctrl.datePicker.isOpened = true;
          }

          /**
           * @function
           * @name updateChange
           * @description update task capture
           */
          function updateChange() {
            if ($ctrl.asDefaultValue) {
              if (_.get($ctrl.renderField, 'process.id')) {
                if ($ctrl.field.default_value_enabled && !$ctrl.currentDateAndTimeSelected && ($ctrl.field.default_value > 1095 || $ctrl.field.default_value < -1095)) {
                  $ctrl.field.default_value = ($ctrl.field.default_value > 1095) ? 1095 : ($ctrl.field.default_value < -1095) ? -1095 : angular.noop();
                  $ctrl.dateFieldValidationError = true;
                  return;
                } else {
                  $ctrl.dateFieldValidationError = false;
                }
              }
              if ($ctrl.renderField.renderFormCtrl) {
                $ctrl.renderField.renderFormCtrl.onFieldUpdate({ field: angular.copy($ctrl.field) });
              }
            } else {
              if (!$ctrl.field.value) {
                $ctrl.splittedTime = {};
              }
              if ($ctrl.field.value && $ctrl.validationErrors[$ctrl.field.id]) {
                delete $ctrl.validationErrors[$ctrl.field.id];
              }
              $ctrl.field.value = $ctrl.field.value ? FieldService.getDateInPayloadFormat($ctrl.field.value) : $ctrl.field.value;
              if ($ctrl.taskCtrl) {
                $ctrl.taskCtrl.updateTaskCapture(angular.copy($ctrl.field));
              } else {
                if ($ctrl.renderField.renderFormCtrl) {
                  $ctrl.renderField.renderFormCtrl.onFieldUpdate({ field: angular.copy($ctrl.field) });
                }
              }
              if (moment($ctrl.field.value).isValid()) {
                updateDatePicker($ctrl.field.value);
              }
            }
          }

          /**
           * @function
           * @name updateDatePicker
           * @description Update day/month/year value in datepicker
           * @param date
           */
          function updateDatePicker(date) {
            $ctrl.field.value = FieldService.getDateInDatepickerDisplayFormat(date);
            if ($ctrl.field.collect_time) {
              $ctrl.splittedDateTime = angular.copy($ctrl.field.value);
            }
            $ctrl.splittedTime = {
              day: moment($ctrl.field.value).format('DD'),
              month: moment($ctrl.field.value).format('MM'),
              year: moment($ctrl.field.value).format('YYYY')
            };
          }

          /**
           * @function
           * @name isValidMonth
           * @param month
           * @description Check if a month is valid
           * @returns {Number|boolean}
           */
          function isValidMonth(month) {
            return parseInt(month) && month <= 12;
          }

          /**
           * @function
           * @name isValidDay
           * @param day
           * @description Check if a day is valid
           * @returns {Number|boolean}
           */
          function isValidDay(day) {
            return parseInt(day) && day <= 31;
          }

          /**
           * @function
           * @name isValidYear
           * @param year
           * @description Check if a number is valid year
           * @returns {Number|boolean}
           */
          function isValidYear(year) {
            return parseInt(year) && year > 1900 && year < 9999;
          }

          /**
           * @function
           * @name isValidDate
           * @description Check full date is valid
           */
          function isValidDate() {
            if (isValidMonth($ctrl.splittedTime.month) && isValidDay($ctrl.splittedTime.day) && isValidYear($ctrl.splittedTime.year)) {
              var newDate = new Date();
              newDate.setDate($ctrl.splittedTime.day);
              newDate.setYear($ctrl.splittedTime.year);
              newDate.setMonth($ctrl.splittedTime.month - 1);
              $ctrl.isTimeValid = !$ctrl.splittedDateTime;
              if (_.get($ctrl.field, 'collect_time')) {
                newDate.setHours(moment($ctrl.splittedDateTime).format('HH'));
                newDate.setMinutes(moment($ctrl.splittedDateTime).format('mm'));
              }
              $ctrl.field.value = newDate;
              updateTaskListener = $timeout(function () {
                updateChange();
              }, 1000);
              return;
            }
            $ctrl.field.value = '';
          }

          /**
           * @function
           * @name onTimeChange
           * @description on Time Change
           */
          function onTimeChange() {
            $timeout.cancel(updateTaskListener);
            $ctrl.isTimeValid = false;
            isValidDate();
          }

          function fieldBlur() {
            if ($ctrl.asDefaultValue) {
              if ($ctrl.currentDateAndTimeSelected) {
                $ctrl.field.default_value = 0;
              } else {
                $ctrl.field.default_value =  $ctrl.field.default_value || 1;
              }
              updateChange();
            }
            $rootScope.$emit('FIELD:BLUR-' + $ctrl.field.id, { field: $ctrl.field });
          }

          function fieldFocus() {
            $rootScope.$emit('FIELD:FOCUS-' + $ctrl.field.id, { field: $ctrl.field });
          }

          function validateMinMaxValue() {
            updateChange(true);
          }

          updateTaskStatusListener = $rootScope.$on('TASK:UPDATE_TASK_STATUS', function (event, data) {
            if (data.taskId === $ctrl.taskCtrl.task.id) {
              $ctrl.isDisabled = data.isCompleted;
              if (!$ctrl.field.value) {
                $ctrl.splittedTime = {};
              }
            }
          });
        }
    });
})();