/**
 * @ngdoc Component
 * @name tallyfy.process.component.templateEditActivity
 * @module tallyfy.process
 *
 * @description
 * A component to edit the details of a template permissions
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.process')
    .component('templateEditActivity', {
      bindings: {
        auditableId: '<?',
        drawerTab: '<?',
        auditableType: '<?',
        activityType: '<?',
        infiniteContent: '@?',
        isHideLink: '@?',
        processId: '<?',
        activityView: '<?',
        blueprintId: '<?',
        activityList: '<?',
        entity: '<'
      },
      templateUrl: 'app/modules/process/components/edit/info/activity/template-edit-activity.html',
      controller:
        /*@ngInject*/
        function ($state, CONFIG, ProcessService, FieldService, TasksService, _, moment, $rootScope, FroalaService, UsersService, store, DateUtils, USER_STATE, OrganizationsService, $q, Helper, StepService, FolderService) {
          var $ctrl = this,
            activityFeedsCurrentPage = 1,
            unRegisteredEventActivityUpdated,
            taskTypes = StepService.getStepTypes(),
            formFields = FieldService.getFields();

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public method
           */
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.nextLoadOfActivityFeeds = nextLoadOfActivityFeeds;
          $ctrl.isCommentActivity = isCommentActivity;
          $ctrl.isTaskActivity = isTaskActivity;
          $ctrl.isProcessActivity = isProcessActivity;
          $ctrl.isBlueprintActivity = isBlueprintActivity;
          $ctrl.isStepActivity = isStepActivity;
          $ctrl.setCommentUserPill = setCommentUserPill;
          $ctrl.getActivityLink = getActivityLink;
          $ctrl.getActorData = getActorData;
          $ctrl.getActorText = getActorText;
          $ctrl.getActivityFeedType = getActivityFeedType;
          $ctrl.convertArray = convertArray;
          $ctrl.getStepDeadline = getStepDeadline;
          $ctrl.getTaskType = getTaskType;
          $ctrl.getFieldType = getFieldType;
          $ctrl.getActivityDocSummary = getActivityDocSummary;
          $ctrl.getActivityPermSummary = getActivityPermSummary;
          $ctrl.getAssigneesUser = getAssigneesUser;
          $ctrl.getAssigneesGuest = getAssigneesGuest;
          $ctrl.toggleMore = toggleMore;

          /**
           * public properties
           */
          $ctrl.activityFeedsLoading = false;
          $ctrl.activityFeeds = [];
          $ctrl.activityFeedsMetaData = [];
          $ctrl.folderList = [];
          $ctrl.isShowMoreButton = [];
          $ctrl.stringLimit = [];
          $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST
            ? _.get($rootScope.identity, 'guest.organization.id')
            : _.get($rootScope.identity, 'default_organization.id');
          $ctrl.apiHost = CONFIG.API_HOST;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            store.getUsers().then(function (data) {
              $ctrl.activeUsers = data ? UsersService.getAllOrgUsers(data) : {};
            });
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @param {*} bindings
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(bindings) {
            if (bindings.entity && bindings.entity.currentValue) {
              generateEntityActivityFeeds(bindings.entity.currentValue.activities.data, bindings.entity.currentValue.rule_executed.data);
            } else {
              if (_.get(bindings, 'drawerTab.currentValue.value') === "activity") {
                activityFeedsCurrentPage = 1;
                $ctrl.activityFeeds = [];
                if (!$ctrl.activityList) {
                  getActivityFeeds();
                }
              }
              if (bindings.activityList && bindings.activityList.currentValue) {
                var activity = bindings.activityList.currentValue;
                if (activity.length > 0) {
                  $ctrl.activityFeedsLoading = true;
                  _.forEach(activity || [], function (feeds, i) {
                    $q.all([
                      OrganizationsService.get({ org: feeds.audit_state.imported_from }),
                      OrganizationsService.get({ org: feeds.audit_state.imported_to })
                    ]).then(function (res) {
                      angular.extend(feeds, {
                        imported_from_name: _.get(res[0], 'data.name'),
                        imported_to_name: _.get(res[1], 'data.name')
                      });
                      if (i === (activity.length - 1)) {
                        $ctrl.activityFeedsLoading = false;
                        $ctrl.activityFeeds = activity;
                      }
                    });
                  });
                }
              }
            }
          }

          function toggleMore(flag) {
            $ctrl.stringLimit[flag] = $ctrl.isShowMoreButton[flag] ? 200 : 'full';
            $ctrl.isShowMoreButton[flag] = !$ctrl.isShowMoreButton[flag];
          }

          function generateEntityActivityFeeds(activities, ruleExecuted) {
            $ctrl.activityFeeds = sortActivities(activities);
            $ctrl.ruleExecutedFeeds = sortActivities(ruleExecuted);
          }

          function sortActivities(activities) {
            return activities.sort(function (a, b) {
              return moment(a.created_at).valueOf() - moment(b.created_at).valueOf();
            });
          }

          function getActivityFeedType(activity) {
            return !!(activity.toLowerCase().match("kick-off") || activity.toLowerCase().match("kick off"));
          }

          function convertArray(feeds, value) {
            if (!_.includes(feeds, ["text", "textarea", "file"])) {
              var data = _.isString(value) ? JSON.parse(value) : value;
              return _.join(_.map(data, 'text'), ', ');
            } else {
              return value;
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unRegisteredEventActivityUpdated();
          }

          /**
           * @ngdoc method
           * @name getActivityLink
           * @description
           * Set link to activity feed
           * @param {Object} feeds 
           * @param {integer} $index 
           */
          function getActivityLink(feeds, $index) {
            if (!_.get(feeds, 'type[' + $index + ']') || !_.get(feeds, 'verb[' + $index + ']')) {
              return '';
            }
            $ctrl.processId = _.get(feeds, 'audit_state.run_id') !== null ? _.get(feeds, 'audit_state.run_id') : ($state.is("dashboard") ? '' : $ctrl.processId);
            var feedsType = _.get(feeds, 'type[' + $index + ']', ''), feedVerb = _.get(feeds, 'verb[' + $index + ']', ''), feedField = _.get(feeds, 'field[' + $index + ']', '');
            return feedsType.toLowerCase() === 'process' ?
              "run.view({ org_id: $ctrl.organizationId, id: feeds.audit_state.id, activeTask: '' })"
              : feedsType.toLowerCase() === 'task' ? (feedVerb.toLowerCase() === 'owner-updated' || feedVerb.toLowerCase() === 'completed') ? (!$ctrl.processId ? "home({ org_id: $ctrl.organizationId, activeTask: feeds.audit_state.id })" : "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.audit_state.id })")
                : feedVerb.toLowerCase() === 'linked' ? "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.audit_state.id })"
                  : (feedVerb.toLowerCase() === 'created' || feedVerb.toLowerCase() === 'updated') ? (!!$ctrl.processId ? "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.audit_state.id })" : "home({ org_id: $ctrl.organizationId, activeTask: feeds.audit_state.id })") : ""
                : feedVerb.toLowerCase() === 'commented' ? ($ctrl.activityView === 'blueprint' && $ctrl.blueprintId) ? "process.edit({ org_id: $ctrl.organizationId, slug: $ctrl.blueprintId, step: feeds.parentable_id , view: 'edit' })"
                  : ($ctrl.activityView === 'process') ? (!$ctrl.processId ? "home({ org_id: $ctrl.organizationId, activeTask: feeds.audit_state.task_id })" : "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.audit_state.task_id })") : ""
                  : feedsType.toLowerCase() === 'step' ? "process.edit({ org_id: $ctrl.organizationId, slug: feeds.parentable_id, step: feeds.audit_state.id, view: 'edit' })"
                    : feedsType.toLowerCase() === 'group' ? "settings.org.groups({ groupId: feeds.audit_state.id, tab: 'table' })"
                      : (feedsType.toLowerCase() === 'rule' || (feedField && feedField.toLowerCase() === 'rules_action')) ? "process.edit({ org_id: $ctrl.organizationId, slug: feeds.parentable_id, step: feeds.audit_state.id, view: 'edit' })"
                        : (feedsType.toLowerCase() === 'form') ? "process.edit({ org_id: $ctrl.organizationId, slug: feeds.parentable_id, step: feeds.audit_state.id, view: 'edit' })"
                          : feedsType.toLowerCase() === 'user' ? "userProfile({ org_id: $ctrl.organizationId, id: feeds.audit_state.id })"
                            : feedsType.toLowerCase() === 'task' ?
                              _.get(feeds, 'audit.data.is_oneoff_task') ?
                                "home({ org_id: $ctrl.organizationId, status: feeds.audit_state.status_label==='completed'?'completed':'to-do', activeTask: feeds.audit_state.id })"
                                : ($ctrl.activityView === 'process' && $ctrl.processId) ?
                                  "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.audit_state.id })"
                                  : "run.view({ org_id: $ctrl.organizationId, id: feeds.parentable_id, activeTask: feeds.audit_state.id })"
                              : feedVerb.toLowerCase() === 'archived' ? "process.templates({ org_id: $ctrl.organizationId, status: 'archived', page: 1})"
                                : feedVerb.toLowerCase() === 'commented' ?
                                  ($ctrl.activityView === 'blueprint' && $ctrl.blueprintId) ? "process.edit({ org_id: $ctrl.organizationId, slug: $ctrl.blueprintId, step: feeds.parentable_id , view: 'edit' })"
                                    : ($ctrl.activityView === 'process' && $ctrl.processId) ? "run.view({ org_id: $ctrl.organizationId, id: $ctrl.processId, activeTask: feeds.parentable_id })"
                                      : ''
                                  : feedsType.toLowerCase() === 'blueprint' ?
                                    feeds.audit_state.status === 'archived' ? "process.templates({ org_id: $ctrl.organizationId, status: 'archived', page: 1})"
                                      : "process.edit({ org_id: $ctrl.organizationId, slug: feeds.audit_state.id, step: feeds.parentable_id , view: 'edit' })"
                                    : '';
          }

          /**
           * @ngdoc method
           * @name getActivityFeeds 
           * @description fetch activity feeds
           */
          function getActivityFeeds() {
            var params = {
              per_page: 10,
              page: activityFeedsCurrentPage
            };
            if ($ctrl.auditableId) {
              angular.extend(params, { entity_id: $ctrl.auditableId });
            }
            if ($ctrl.auditableType) {
              angular.extend(params, { entity_type: $ctrl.auditableType });
            }
            if ($ctrl.activityType) {
              angular.extend(params, { type: $ctrl.activityType });
            }
            $ctrl.activityFeedsLoading = true;
            ProcessService.getActivityFeeds(params).then(function (response) {
              _.forEach(response.data || [], function (feeds) {
                angular.extend(feeds, { created_at: DateUtils.toLocal(feeds.created_at).format() });
              });
              getFolderDetails(response.data || []);
              $ctrl.metadata = response.meta;
              $ctrl.activityFeedsLoading = false;
            }, function () {
              $ctrl.activityFeedsLoading = false;
            });
          }

          function getFolderDetails(data) {
            if (data && data.length > 0) {
              _.forEach(data || [], function (feed) {
                if (feed.type[0].toLowerCase() === 'folder' && _.get(feed, 'audit_state.parent_id')) {
                  var folder = _.find($ctrl.folderList, { id: feed.audit_state.parent_id });
                  if (!folder) {
                    FolderService.getFolderById({ id: feed.audit_state.parent_id, with: 'parent', skipNotFound: true })
                      .then(function (response) {
                        var breadcrumb = _.get(response, 'data');
                        if (breadcrumb && breadcrumb.parent && breadcrumb.parent.data) {
                          var totalLevels = prepareBreadcrumbData(breadcrumb, 1);
                          angular.extend(breadcrumb, { totalLevels: totalLevels });
                        }
                        $ctrl.folderList.push(breadcrumb);
                        feed.audit_state.parent = breadcrumb;
                      });
                  } else {
                    feed.audit_state.parent = folder;
                  }
                }
                _.each(feed.field, function (f, i) {
                  if ((f === "folder_id" || f === "parent_id") && _.get(feed, 'audit_state.folder.parent_id')) {
                    var folder = _.find($ctrl.folderList, { id: feed.audit_state.folder.parent_id });
                    if (!folder) {
                      FolderService.getFolderById({ id: feed.audit_state.folder.parent_id, with: 'parent', skipNotFound: true })
                        .then(function (response) {
                          var breadcrumb = _.get(response, 'data');
                          if (breadcrumb && breadcrumb.parent && breadcrumb.parent.data) {
                            var totalLevels = prepareBreadcrumbData(breadcrumb, 1);
                            angular.extend(breadcrumb, { totalLevels: totalLevels });
                          }
                          $ctrl.folderList.push(breadcrumb);
                          feed.audit_state.folder.parent = breadcrumb;
                        });
                    } else {
                      feed.audit_state.folder.parent = folder;
                    }
                  }
                  if ((f === "folder_id" || f === "parent_id") && feed.old_value[i]) {
                    var folder = _.find($ctrl.folderList, { id: feed.old_value[i] });
                    if (!folder) {
                      FolderService.getFolderById({ id: feed.old_value[i], with: 'parent', skipNotFound: true })
                        .then(function (response) {
                          var breadcrumb = _.get(response, 'data');
                          if (breadcrumb && breadcrumb.parent && breadcrumb.parent.data) {
                            var totalLevels = prepareBreadcrumbData(breadcrumb, 1);
                            angular.extend(breadcrumb, { totalLevels: totalLevels });
                          }
                          $ctrl.folderList.push(_.get(response, 'data'));
                          feed.old_value[i] = _.get(response, 'data');
                        });
                    } else {
                      feed.old_value[i] = folder;
                    }
                  }
                });
              });
            }
            $ctrl.activityFeeds = _.concat($ctrl.activityFeeds || [], data);
          }

          function getAssigneesUser(assignees) {
            if (_.get(assignees, 'users', []).length > 0) {
              var user = [];
              _.forEach(assignees.users || [], function (u, i) {
                var val = _.find($ctrl.activeUsers, { id: u });
                if (val) {
                  user.push(val);
                }
              });
              return user ? _.join(_.map(user, 'full_name'), ', ') : '-';
            }
          }

          function getAssigneesGuest(assignees) {
            return _.get(assignees, 'guests') ? _.join(assignees.guests, ',') : '-';
          }

          function prepareBreadcrumbData(breadcrumb, level) {
            angular.extend(breadcrumb, { level: level });
            if (breadcrumb.parent) {
              level++;
              return prepareBreadcrumbData(breadcrumb.parent.data, level);
            }
            return level;
          }

          /**
           * @ngdoc method
           * @name nextLoadOfActivityFeeds 
           * @description next load of activity feeds
           */
          function nextLoadOfActivityFeeds() {
            if (!$ctrl.drawerTab || _.get($ctrl.drawerTab, 'value') !== "activity") {
              return;
            }
            if ($ctrl.activityFeeds && _.get($ctrl.metadata, 'pagination.total_pages') <= activityFeedsCurrentPage) {
              return;
            }
            activityFeedsCurrentPage++;
            getActivityFeeds();
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @description set default avatar
           * @param {string} avatar
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          function getActorData(actor) {
            return _.find($ctrl.activeUsers, Helper.isObjectEmpty(actor.email) ? { type: 'bot' } : { email: actor.email });
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {*} actor
           * @return {string} first character of name
           */
          function defaultAvatarText(actor) {
            var firstName = Helper.isObjectEmpty(actor.first_name) ? actor.email : actor.first_name,
              lastName = Helper.isObjectEmpty(actor.last_name) ? actor.last_name : '';
            return TasksService.setDefaultAvatarText(firstName, lastName);
          }

          function getActorText(actor) {
            var data = getActorData(actor);
            if (data) {
              return !Helper.isObjectEmpty(data.full_name) ? data.full_name + ' (' + data.email + ')'
                : data.email;
            } else {
              return actor.email;
            }
          }

          /**
           * @ngdoc method
           * @name isCommentActivity
           * @public
           * @description check type is comment
           * @param {string} type 
           * @param {string} verb
           */
          function isCommentActivity(type, verb) {
            if (type.toLowerCase() === 'comment') {
              return _.includes(['commented', 'updated', 'issue-reported', 'issue-resolved', 'owner-updated', 'deadline-updated'], verb.toLowerCase());
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name setCommentUserPill
           * @public
           * @description set comment in user pill UI
           * @param {string} comment
           */
          function setCommentUserPill(comment) {
            comment = _.startsWith(comment, 'key:reopenComment, ') ? comment.replace('key:reopenComment, ', '') : comment;
            return FroalaService.getViewContent(comment, $ctrl.activeUsers, []);
          }

          /**
           * @ngdoc method
           * @name isTaskActivity
           * @public
           * @description check type is task
           * @param {string} type 
           * @param {string} verb
           */
          function isTaskActivity(type, verb) {
            if (type.toLowerCase() === 'task') {
              return _.includes(['deadline-updated', 'completed', 're-opened', 'created'], verb.toLowerCase());
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name isProcessActivity
           * @public
           * @description check type is process
           * @param {string} type 
           * @param {string} verb
           */
          function isProcessActivity(type, verb) {
            if (type.toLowerCase() === 'process') {
              return _.includes(['created', 'archived', 'completed', 'unarchived'], verb.toLowerCase());
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name isBlueprintActivity
           * @public
           * @description check type is blueprint
           * @param {string} type 
           * @param {string} verb
           */
          function isBlueprintActivity(type, verb) {
            if (type.toLowerCase() === 'blueprint') {
              return _.includes(['created', 'archived', 'unarchived', 'deleted', 're-ordered'], verb.toLowerCase());
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name isStepActivity
           * @public
           * @description check type is step
           * @param {string} type 
           * @param {string} verb
           */
          function isStepActivity(type, verb) {
            if (type.toLowerCase() === 'step') {
              return _.includes(['re-ordered', 'updated', 'deleted'], verb.toLowerCase());
            }
            return false;
          }

          function getStepDeadline(deadline, type) {
            if (type === 'step') {
              var data = _.isString(deadline) ? JSON.parse(deadline) : deadline;
              return data.value + ' ' + data.unit;
            } else if (type === 'task') {
              return moment(moment(DateUtils.toTimezone($ctrl.deadline).value()).toDate()).format('MMM D, YYYY h:mma');
            }
          }

          function getTaskType(type) {
            type = type.replace(/^"|"$/g, '');
            var ret = _.find(taskTypes, { value: type });
            return ret ? ret.label : '';
          }

          function getFieldType(type) {
            type = type.replace(/^"|"$/g, '');
            var ret = _.find(formFields, { field_type: type });
            return ret ? ret.name : '';
          }

          function getActivityDocSummary(activity) {
            return !!(activity.toLowerCase().match("summary"));
          }

          function getActivityPermSummary(activity) {
            return !!(activity.toLowerCase().match("permissions"));
          }

          // activity feeds updated event handler
          unRegisteredEventActivityUpdated = $rootScope.$on('ACTIVITY:UPDATES', function (e, data) {
            if (data) {
              $ctrl.auditableId = data.auditableId;
              $ctrl.auditableType = data.auditableType;
              activityFeedsCurrentPage = 1;
              $ctrl.activityFeeds = [];
              getActivityFeeds();
            }
          });
        }
    });
})();