/**
 * @ngdoc Service
 * @name tallyfy.run.RunsService
 *
 * @module tallyfy.run
 *
 * @description
 * RunsService
 *
 * @author Feroj Bepari ( gmail::feroj21@gmail.com, skype :: feroj21 )
 * @modifiedby Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .service('RunsService', RunsService);

  /*@ngInject*/
  function RunsService($q, RunRepository, _, moment, $filter, DateUtils, $confirm, BLUEPRINT_TYPE, $timeout, $state) {
    var self = this;

    angular.extend(self, RunRepository);

    /**
     * @function getRunModel
     * @description an helper function which returns empty model of a task
     *
     * @return {Object} Task model
     */
    self.getRunModel = function () {
      return {
        name: '',
        checklist_id: '',
        checklist_public_name: '',
        summary: '',
        metadata: {},
        tasks: [],
        tags: [],
        prerun: {
        },
        trackers: {
          users: [],
          groups: [],
          guests: []
        },
        folders: []
      };
    };

    /**
     * @function getBarClass
     * @description determines a progress bar css class based on progress of run
     * and issues reported to any tasks
     * @param {object} run A run object
     *
     * @return {string} A name of css class
     */
    self.getBarClass = function (run) {
      if (run) {
        var hasProblem = (run.status === 'problem');
        if (run.archived_at) {
          return 'progress-archive';
        }
        if ((hasProblem || run.due_date_passed) && run.progress.complete !== run.progress.total) {
          return 'progress-danger';
        }
        if (run.due_soon && !(hasProblem && run.due_date_passed)) {
          return 'progress-warning';
        }
        if (run.progress.complete === run.progress.total || !(run.due_date_passed && run.due_soon && hasProblem)) {
          return 'progress-success';
        }
      } else {
        return '';
      }
    };

    /**
     * @function createRun
     * @description Create a run
     * @param {object} data
     * @param {object} params
     * @return Object
     */
    self.createRun = function (data, params) {
      return RunRepository.create(params, data);
    };

    /**
     * @function getRunFormFields
     * @description get form field by run id
     * @param {object} data
     * @return Object
     */
    self.getRunFormFields = function (params) {
      return RunRepository.getRunFormFields(params).$promise;
    };

    /**
     * @function
     * @name getNewDate
     * @description set new date when deadline is null
     * @param {Object} splittedTime
     * @returns {Object}
     */
    self.getNewDate = function (splittedTime) {
      var newDate = DateUtils.toTimezone(splittedTime).value().toDate();
      newDate.setDate(splittedTime.day);
      newDate.setYear(splittedTime.year);
      newDate.setMonth(splittedTime.month - 1);
      newDate.setHours(splittedTime.hours);
      newDate.setMinutes(splittedTime.minutes);
      return newDate;
    };

    self.getViewHelperRunStatus = function (run) {
      var hasIssue = (run.status === 'problem'),
        isCompleted = !run.due_soon && !run.due_date_passed && run.progress.percent === 100,
        isRunTypeForm = _.get(run, 'type') === BLUEPRINT_TYPE.FORM,
        result = {};
      if (run.status === 'archived') {
        result.statusClass = 't-battleship-grey';
        result.statusId = 'archived';
        result.runStatus = $filter('translate')('runs.process.processStatus.archived');
        result.icon = 'fa-archive';
      } else if (hasIssue) {
        result.statusClass = 't-red-light';
        result.statusId = run.due_soon ? 'issueDueSoon' : (run.due_date_passed ? 'issueOverdue' : 'issueOnTime');
        result.runStatus = (run.due_soon) ? $filter('translate')('runs.process.processStatus.issueDueSoon') : (run.due_date_passed) ? $filter('translate')('runs.process.processStatus.issueOverdue') : $filter('translate')('runs.process.processStatus.issueOntime');
        result.hasIssue = true;
        result.icon = 'fa-exclamation-triangle';
      } else if (isCompleted) {
        result.statusClass = 'text-primary-tallfy';
        result.statusId = moment(run.completed_at).isBefore(moment(run.max_task_deadline)) || !run.max_task_deadline ? 'completed' : 'completedOverdue';
        result.runStatus = (moment(run.completed_at).isBefore(moment(run.max_task_deadline)) || !run.max_task_deadline) ? (isRunTypeForm ? $filter('translate')('runs.process.processStatus.form.completed') : (run.late_tasks > 0) ? $filter('translate')('runs.process.processStatus.completedOverdue', { lateTaskCount: run.late_tasks }) : $filter('translate')('runs.process.processStatus.completed')) : $filter('translate')('runs.process.processStatus.completedOverdue', { lateTaskCount: run.late_tasks });
        result.icon = 'fa-thumbs-up';
      } else {
        if (run.due_date_passed) {
          result.statusClass = 't-red-light';
          result.statusId = 'overdue';
          result.runStatus = $filter('translate')('runs.process.processStatus.overdue');
          result.icon = 'fa-exclamation-circle';
        } else if (run.due_soon) {
          result.statusClass = 'cs-due';
          result.statusId = 'dueSoon';
          result.runStatus = $filter('translate')('runs.process.processStatus.dueSoon');
          result.icon = 'fa-clock';
        } else {
          result.statusClass = 'text-primary-tallfy';
          result.statusId = isRunTypeForm ? 'onTime' : 'notCompleted';
          result.runStatus = isRunTypeForm ? $filter('translate')('runs.process.processStatus.notCompleted') : $filter('translate')('runs.process.processStatus.onTime');
        }
      }
      return result;
    };

    self.getClosestRunDeadline = function (tasks) {
      if (tasks.length) {
        var deadlines = _.compact(_.map(tasks, function (task) {
          if (task.status !== 'completed' && task.status !== 'auto-skipped') {
            return moment(task.deadline);
          }
        }));
        if (deadlines.length > 0) {
          return moment(DateUtils.toLocal(moment.min(deadlines)));
        }
      }
      return angular.noop();
    };

    /**
     * @function activeProcessCount
     * @description fetch active processes
     * extract total number of processes
     * parse response and return counts of process in process
     *
     * @return $promise
     */
    self.activeProcessCount = function () {
      var deffered = $q.defer();
      RunRepository.getRunsData({
        status: "active",
        per_page: 1
      }).then(function (response) {
        var activeRunCount = _.get(response, 'meta.pagination.total');
        deffered.resolve(activeRunCount);
      }, function (error) {
        deffered.resolve(error);
      });
      return deffered.promise;
    };

    /**
     * @function setEmptySummaryOfRun
     * @description Handle empty summary case
     * @param {object} run
     * @return String / null
     */
    self.setEmptySummaryOfRun = function (run) {
      return (_.get(run, 'summary') === '') ? null : _.get(run, 'summary', null);
    };

    /**
     * @ngdoc method
     * @name getRunKOFields
     * @param {*} runPreruns
     * @description
     * get prerun object pick only id and value
     */
    self.getPrerunValues = function (runPreruns) {
      return _.map(runPreruns, function (prerun) {
        var kof = {};
        if (prerun.field_type === 'date') {
          kof[prerun.alias] = (prerun.value) ? (moment(prerun.value).format() || null) : null;
        } else if (prerun.field_type === 'radio') {
          kof[prerun.alias] = prerun.value ? (prerun.value.text || null) : null;
        } else {
          kof[prerun.alias] = prerun.value || null;
        }
        return kof;
      });
    };

    /**
     * @ngdoc method
     * @name getTakenDurationToCompleteProcess
     * @param {Date} momentStartedDate
     * @param {Date} momentCompletedDate
     * @returns {String}
     */
    self.getTakenDurationToCompleteProcess = function (momentStartedDate, momentCompletedDate) {
      if (momentCompletedDate.diff(momentStartedDate, 'years')) {
        var years = momentCompletedDate.diff(momentStartedDate, 'years');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: years, durationUnit: years === 1 ? 'year' : 'years' }, 'messageformat');
      }
      if (momentCompletedDate.diff(momentStartedDate, 'months')) {
        var months = momentCompletedDate.diff(momentStartedDate, 'months');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: months, durationUnit: months === 1 ? 'month' : 'months' }, 'messageformat');
      }
      if (momentCompletedDate.diff(momentStartedDate, 'days')) {
        var days = momentCompletedDate.diff(momentStartedDate, 'days');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: days, durationUnit: days === 1 ? 'day' : 'days' }, 'messageformat');
      }
      if (momentCompletedDate.diff(momentStartedDate, 'hours')) {
        var hours = momentCompletedDate.diff(momentStartedDate, 'hours');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: hours, durationUnit: hours === 1 ? 'hour' : 'hours' }, 'messageformat');
      }
      if (momentCompletedDate.diff(momentStartedDate, 'minutes')) {
        var minutes = momentCompletedDate.diff(momentStartedDate, 'minutes');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: minutes, durationUnit: minutes === 1 ? 'minute' : 'minutes' }, 'messageformat');
      }
      if (momentCompletedDate.diff(momentStartedDate, 'seconds')) {
        var seconds = momentCompletedDate.diff(momentStartedDate, 'seconds');
        return $filter('translate')('runs.process.tookDurationMsg', { durationValue: seconds, durationUnit: seconds === 1 ? 'second' : 'seconds' }, 'messageformat');
      }
    };

    /**
    * @name getPermission
    * @description
    * get permission list
    */
    self.getRunPermission = function (params) {
      return RunRepository.getRunsPermissions(params).$promise;
    };

    /**
     * @name setPermission
     * @description
     * set permission list
     */
    self.setRunPermission = function (data, params) {
      angular.extend(params, data);
      return RunRepository.setRunsPermissions(params).$promise;
    };

    /**
     * @name openConfirmRunDeletion
     * @description
     * set permission list
     */
    self.openConfirmRunDeletion = function (run) {
      return $confirm({
        'header': $filter('translate')('template.permanentlyDeleteComponent.header', {
          componentName: $filter('translate')('process.process')
        }),
        'body': $filter('translate')('template.permanentlyDeleteComponent.body', {
          entityName: run.name,
          componentName: $filter('translate')('process.process')
        }),
        'buttons': {
          'accept': $filter('translate')('template.permanentlyDeleteComponent.deleteEntity', {
            componentName: $filter('translate')('process.process')
          }),
          'cancel': 'global.confirmAction.buttons.close'
        },
        modalType: 'modal-danger'
      });
    };

    self.getRunsByChecklist = function (params) {
      return RunRepository.getRunsByChecklist(params).$promise;
    };

    self.getChecklistFormFields = function (params) {
      return RunRepository.getChecklistFormFields(params).$promise;
    };

    self.updateGuestPrerun = function (params, body) {
      return RunRepository.updateGuestPrerun(params, body);
    };

    self.getProcessFormFieldsData = function (formFieldsArr) {
      var formFieldsData = {};
      for (var i = 0; i < formFieldsArr.length; i++) {
        formFieldsData[formFieldsArr[i].id] = formFieldsArr[i].field_value;
      }
      return formFieldsData;
    };

    self.getRunStageTask = function (params) {
      var deffered = $q.defer();
      RunRepository.getRunStageTask(params).$promise
        .then(function (response) {
          deffered.resolve(response);
        }, function (error) {
          deffered.resolve({ data: [] });
        });
      return deffered.promise;
    }

    self.getFinalAccordionOptions = function () {
      return [
        {
          id: 'kickoff-form',
          label: 'kickoffForm',
          isExpanded: false
        },
        {
          id: 'process-metadata',
          label: 'processMetadata',
          isExpanded: false
        }
      ]
    }
  }
})();
