/**
 * @ngdoc Component
 * @name tallyfy.process.component.processLibrary
 * @module tallyfy.process
 *
 * @description
 * A component to manage process list page
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.process')
    .component('processLibrary', {
      templateUrl: 'app/modules/process/components/library/process-library.html',
      controller:
        /*@ngInject*/
        function (_, $q, USER_STATE, Growl, blockUI, ProcessService, $stateParams, $timeout, $state, $filter, $log, DESCRIPTIONSIZE, ProcessModalService,
                  Helper, AccountService, preferencesStore, $rootScope, AuthPlan, FolderService, ProgressBar, BLUEPRINT_TYPE, $cookies, $uibModal,
                  TagsService, $confirm, RunsDashboardService, PublicLibraryService, ENV_CONFIG, PublicTagService, $analytics, store, StepService,
                  UtilsService, TasksService, $window, OrganizationsService, FavoriteService, UsersService) {
          var $ctrl = this, growl = new Growl(),folderSelectedWatcher,
            blockUIMain = blockUI.instances.get('processLibraryWrapper'),
            blockUI = blockUI.instances.get('processLibrary'),
            body = angular.element('body'),
            onFolderClickWatcher,
            paneIsScrollEventHandler,
            identity = _.get($rootScope, 'identity'),
            isFetchingFavoriteCount = false,
            folderbroadUpdateHandler,
            blueprintRemoveListHandler,
            blueprintUpdatedFavoriteHandler,
            blueprintFavoriteRemovedHandler,
            addNewFolderClickEventHandler,
            windowElem = angular.element($window);

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public properties
           */
          $ctrl.isCreateNewOrg = false;
          $ctrl.isSearch = false;
          $ctrl.allMenuLoaded = false;
          $ctrl.isLibrarySplashDismissed = true;
          $ctrl.lastPage = false;
          $ctrl.isBusy = false;
          $ctrl.blueprintItemsToDisplay = [];
          $ctrl.folders = [];
          $ctrl.foldersAsMenu = [];
          $ctrl.pager = [];
          $ctrl.allParentData = [];
          $ctrl.tags = [];
          $ctrl.folderTree = [];
          $ctrl.menuPagination = {};
          $ctrl.tyDragNDropConfig = {};
          $ctrl.showScrolled = false;
          $ctrl.favoriteCount = 0;
          $ctrl.isFavoriteFetched = false; 
          $ctrl.isOpenPopOverFavorite = [];
          $ctrl.hiddenElements = void 0;
          $ctrl.dateFormat = OrganizationsService.getDateFormat();

          $ctrl.pagination = {
            page: 1,
            per_page: 5
          };

          /**
           * public methods
           */
          $ctrl.getAllTags = getAllTags;
          $ctrl.showMoreTag = showMoreTag;
          $ctrl.onFolderChange = onFolderChange;
          $ctrl.onNewFolderAdded = onNewFolderAdded;
          $ctrl.searchProcess = searchProcess;
          $ctrl.loadMore = loadMore;
          $ctrl.startRun = startRun;
          $ctrl.archiveProcess = archiveProcess;
          $ctrl.unArchiveProcess = unArchiveProcess;
          $ctrl.updateParameters = updateParameters;
          $ctrl.duplicateTemplate = ProcessService.showDuplicateTemplateModal;
          $ctrl.clearSearchProcess = clearSearchProcess;
          $ctrl.undoArchiveProcess = undoArchiveProcess;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.savePreference = savePreference;
          $ctrl.changeMode = changeMode;
          $ctrl.getTags = getTags;
          $ctrl.openViewMoreModel = openViewMoreModel;
          $ctrl.onNewFolderClick = onNewFolderClick;
          $ctrl.onNewFolderForBlueprint = onNewFolderForBlueprint;
          $ctrl.onMoveToFolder = onMoveToFolder;
          $ctrl.getFolders = getFolders;
          $ctrl.onRemoveFromFolder = onRemoveFromFolder;
          $ctrl.openRequestModal = openRequestModal;
          $ctrl.setTagTextColor = TagsService.isTagTextColor;
          $ctrl.isDocsPlan = AuthPlan.isRestrictedWithDocsPlan();
          $ctrl.openConfirmBPDeletion = openConfirmBPDeletion;
          $ctrl.onFavoriteClick = onFavoriteClick;
          $ctrl.onRemovedFromFavorite = onRemovedFromFavorite;
          $ctrl.onLibrarySplashDismissed = onLibrarySplashDismissed;
          $ctrl.onViewBluePrint = onViewBluePrint;
          $ctrl.shareBPLink = shareBPLink;
          $ctrl.onFilterBlueprintByTag = onFilterBlueprintByTag;
          $ctrl.resetSelectedBP = resetSelectedBP;
          $ctrl.launchProcess = launchProcess;
          $ctrl.isPaginated = isPaginated;
          $ctrl.onMenuItem = onMenuItem;
          $ctrl.stateParams = $stateParams;
          $ctrl.onPageFetch = onPageFetch;
          $ctrl.getFolderList = getFolderList;
          $ctrl.getIndustryAndTopicTags = getIndustryAndTopicTags;
          $ctrl.resetFilter = resetFilter;
          $ctrl.tagSelected = tagSelected;
          $ctrl.scrollBottom = scrollBottom;
          $ctrl.setBlueprintStatus = setBlueprintStatus;
          $ctrl.folderClick = folderClick;
          $ctrl.onPlayClick = onPlayClick;
          $ctrl.onArchiveProcess = onArchiveProcess;
          $ctrl.onSortChange = onSortChange;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.isViewFavorite = isViewFavorite;
          $ctrl.searchFavoriteUsers = searchFavoriteUsers;
          $ctrl.onPinned = onPinned;
          $ctrl.toggleLeftPaneBlueprintShow = toggleLeftPaneBlueprintShow;
          $ctrl.checkBlueprintPermission = checkBlueprintPermission;
          $ctrl.onBreadcrumbClick = onBreadcrumbClick;
          $ctrl.toggleEditPopover = toggleEditPopover;
          $ctrl.onLightRoleErrorMessage = onLightRoleErrorMessage;

          //Attach resize listener
          windowElem.on('resize', onWindowResize);

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            blockUIMain.start();
            $ctrl.blueprintType = PublicLibraryService.blueprintTypes();
            $ctrl.statusOptions = StepService.getProgressStatus();
            if ($rootScope.userState === USER_STATE.MEMBER) {
              $ctrl.organization = _.get($rootScope.identity, 'default_organization', {});
              $ctrl.isCreateNewOrg =  Helper.getDateDiffInHours(_.get(identity, 'default_organization.created_on')) < 1;
            }
            $ctrl.isGuest = $rootScope.userState === USER_STATE.GUEST;
            $ctrl.organizationId = $rootScope.userState === USER_STATE.GUEST
              ? _.get($rootScope.identity, 'guest.organization.id')
              : _.get($rootScope.identity, 'default_organization.id');
            if (!$ctrl.isGuest) {
              getAllTags();
              getUsers();
            }
            $ctrl.isAdminMember = _.isEqual(_.get(identity, 'role', "standard"), "admin");
            $ctrl.isLightMember = _.isEqual(_.get($rootScope, 'identity.role'), "light");
            ProgressBar.start();
            $ctrl.showLoader = true;
            if (!$ctrl.isGuest) {
              ProcessModalService.getActiveProcess();
              getPreferences();
            } else {
              $ctrl.blueprintType[0].isSelected = true;
            }
            $ctrl.filterConfig = ProcessService.getFilterConfig();
            var sortFilterConfig = RunsDashboardService.getBluePrintSortFilterConfig();
            $ctrl.sortOptions = sortFilterConfig.sortOptions;
            $ctrl.sortConfigSlug = sortFilterConfig.sortConfigSlug;
            $ctrl.search = ProcessService.getSearchable();
            $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(DESCRIPTIONSIZE.default);
            if (ProcessService.isForceOnboarding()) {
              $cookies.remove('forcedOnboardingFlow');
              $(".app").removeClass("user-onboarding");
            }
            if ($stateParams.folder_id) {
              $ctrl.folderId = $stateParams.folder_id;
              getAllParentData($stateParams.folder_id);
              getFolderList();
            }
            if (_.get($stateParams, 'sortByType')) {
              var sortByType = _.get($stateParams, 'sortByType', '');
              _.forEach($ctrl.blueprintType, function(value) {
                if (_.includes(sortByType, value.id)) {
                  _.set(value, 'isSelected', true);
                }
              });
            }
            if (!$ctrl.blueprintTypeSelected) {
              $ctrl.blueprintTypeSelected = _.head($ctrl.blueprintType);
            }
            getIndustryAndTopicTags();
            if($stateParams.blueprint_id){
              body.addClass('library-view-modal');
            }
            if (!$ctrl.isGuest && !($ctrl.isFavoriteFetched || isFetchingFavoriteCount)) {
              getFavoriteCount();  
            }
            onWindowResize();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges(change) {
            if (change) {
                $timeout(function(){
                    var isGuest = $rootScope.userState === USER_STATE.GUEST;
                    if (!$ctrl.orgGroups && !isGuest) {
                      store.getLightweightGroups().then(function (response) {
                        $ctrl.orgGroups = response || [];
                        onChangesHandler();
                      }, function () { });
                    } else {
                      onChangesHandler();
                    }
                },0);
            }
          }
          
          function getCurrentUserWatcher(data) {
            return FavoriteService.getCurrentUserWatcher(data);
          }

          function getAnotherUserWatcher(data) {
            return FavoriteService.getAnotherUserWatcher(data);
          }

          function onChangesHandler() {
            $ctrl.statusFilter = $stateParams.status;
            $ctrl.processType = $ctrl.statusFilter === 'archived' ? $ctrl.statusFilter : angular.noop();
            $ctrl.templateUrl = 'app/modules/process/components/library/'
              + ($ctrl.statusFilter === 'archived' ? 'archive-process-library.html'
                : $ctrl.statusFilter === 'home' ? 'blueprint-activity.html'
                  : 'all-process.html');
            $ctrl.pager = ProcessService.getPager({
              current_page: $stateParams.page ? parseInt($stateParams.page) : 1,
              per_page: 20
            });
            getDataInPage($ctrl.pager);
          }

          function setBlueprintStatus(item) {
            StepService.updateBlueprintStatus({
              checklist_id: _.get(item, 'data.id'),
              status: item.statusDropDown.value
            }).then(function (res) {
              angular.extend(item.data, { status: res.data.status });
            });
          }

          /**
           * @function
           * @name isScrollableList
           * @description
           * Get content height and set scroll display or not
           */
          function isScrollableList() {
            $ctrl.showScrolled = false;
            $timeout(function () {
              var viewHeight, contentHeight, bpListElement = document.getElementById('blueprint_list');
              if (bpListElement) {
                viewHeight = bpListElement.clientHeight;
                contentHeight = bpListElement.scrollHeight;
                $ctrl.showScrolled = (contentHeight>viewHeight);
              }
            }, 200);
          }
          
          /**
           * @function
           * @name isScrollableLeftPane
           * @description
           * Get content height and set scroll display or not
           */
          function isScrollableLeftPane() {
            $ctrl.showScrolledLeftPane = false;
            $timeout(function () {
              var leftPaneElement = document.getElementById('leftPane'),
                blueprintLeftPaneElement = document.getElementById('blueprint_left_pane');
              if (leftPaneElement && blueprintLeftPaneElement) {
                var viewHeight = leftPaneElement.clientHeight,
                  contentHeight = blueprintLeftPaneElement.scrollHeight;
                $ctrl.showScrolledLeftPane = (contentHeight>viewHeight);
              }
            }, 500);
          }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            folderSelectedWatcher();
            onFolderClickWatcher();
            paneIsScrollEventHandler();
            folderbroadUpdateHandler();
            blueprintRemoveListHandler();
            blueprintUpdatedFavoriteHandler();
            blueprintFavoriteRemovedHandler();
            addNewFolderClickEventHandler();
            windowElem.off('resize', onWindowResize); //Detatch resize listener
          }

          //Window resize handler
          function onWindowResize () {
            $ctrl.favoritesLimit = $window.innerWidth < 576 ? 1 : 3;
            $ctrl.folderMaxLevel = $window.innerWidth < 576 ? 1 : 2;
            var scrollElement = document.getElementById('leftPaneScroll');
            if (scrollElement) {
              scrollElement.style.display = $window.innerWidth < 1280 ? $ctrl.isLeftPaneBlueprintShow ? 'block' : 'none' : 'block';
            }
          }

          /**
           * @function
           * @name getIndustryAndTopicTags
           * @description get all topic and industry tags
           */
          function getIndustryAndTopicTags() {
            $ctrl.isIndustryTagBusy = $ctrl.isTopicTagBusy = true;
            $q.all([
              PublicTagService.getPublicTags({
                type: 'industry',
                pagination: false,
                all_tags: false,
                is_featured: false
              }),
              PublicTagService.getPublicTags({
                type: 'topic',
                pagination: false,
                all_tags: false,
                is_featured: false
              })
            ]).then(function (res) {
              $ctrl.publicIndustryTags = res[0].data;
              $ctrl.publicTopicTags = res[1].data;
              if (_.get($stateParams, 'sortByIndustry')) {
                var industryParam = _.get($stateParams, 'sortByIndustry', '').split(',');
                _.forEach($ctrl.publicIndustryTags, function(value) {
                  if (_.includes(industryParam, value.title)) {
                    _.set(value, 'isSelected', true);
                  }
                });
                $ctrl.industryTagsSelected = _.join(_.map(_.filter($ctrl.publicIndustryTags, { isSelected: true }), 'title'), ',');
              }
              if (_.get($stateParams, 'sortByTopic')) {
                var topicParam = _.get($stateParams, 'sortByTopic', '').split(',');
                _.forEach($ctrl.publicTopicTags, function(value) {
                  if (_.includes(topicParam, value.title)) {
                    _.set(value, 'isSelected', true);
                  }
                });
                $ctrl.topicTagsSelected = _.join(_.map(_.filter($ctrl.publicTopicTags, { isSelected: true }), 'title'), ','); 
              }
              $ctrl.isIndustryTagBusy = $ctrl.isTopicTagBusy = false;
            }, function () {
              $ctrl.isIndustryTagBusy = $ctrl.isTopicTagBusy = false;
            });
          }
          
          /**
           * @function
           * @name tagSelected
           * @description selected a tag to sort blueprint
           * @param {Object} tag
           * @param {String} type
           * @returns {void} 
           */
          function tagSelected(tag, type) {
            if (type === 'type') {
              _.forEach($ctrl.blueprintType, function(value) {
                _.set(value, 'isSelected', false);
              });
              if (_.get($rootScope.identity, 'default_organization.id')) {
                savePreference('bp_type_filter_config', tag);
              }
            }
            $ctrl.dropdownsIsOpen = false;
            tag.isSelected = !_.get(tag, 'isSelected', false);
            sortPlaceholder(type);
            fetchBluePrintByFilter($stateParams.status);
          }

          /**
           * @function
           * @name sortPlaceholder
           * @description set sort dropdown placeholder text
           * @param {String} type
           * @returns {void} 
           */
          function sortPlaceholder(type) {
            if (type === 'type') {
              $ctrl.blueprintTypeSelected = _.find($ctrl.blueprintType, { isSelected: true });
              $state.transitionTo($state.current, angular.extend($stateParams, {
                sortByType: _.get($ctrl.blueprintTypeSelected, 'id') !== 'all_blueprint' ? _.get($ctrl.blueprintTypeSelected, 'id') : angular.noop()
              }), { notify: false });
            }
            if (type === 'industry') {
              $ctrl.industryTagsSelected = _.join(_.map(_.filter($ctrl.publicIndustryTags, { isSelected: true }), 'title'), ',');
              $state.transitionTo($state.current, angular.extend($stateParams, {
                sortByIndustry: $ctrl.industryTagsSelected
              }), { notify: false });
            }
            if (type === 'topic') {
              $ctrl.topicTagsSelected = _.join(_.map(_.filter($ctrl.publicTopicTags, { isSelected: true }), 'title'), ',');
              $state.transitionTo($state.current, angular.extend($stateParams, {
                sortByTopic: $ctrl.topicTagsSelected
              }), { notify: false });
            }
          }

          /**
           * @function
           * @name resetFilter
           * @description reset selected filter
           * @param {String} type
           * @returns {void}
           */
          function resetFilter(type) {
            if (type === 'type') {
              _.forEach($ctrl.blueprintType, function(value) {
                _.set(value, 'isSelected', false);
              });
            }
            if (type === 'industry') {
              _.forEach($ctrl.publicIndustryTags, function(value) {
                _.set(value, 'isSelected', false);
              });
            }
            if (type === 'topic') {
              _.forEach($ctrl.publicTopicTags, function(value) {
                _.set(value, 'isSelected', false);
              });
            }
            sortPlaceholder(type);
            fetchBluePrintByFilter($stateParams.status);
          }

          function getDataInPage(params) {
              if ($ctrl.isLoadingInitBPs) {
                return;
              }
              $timeout(function () {
                blockUIMain.stop();
              }, 100);
              $ctrl.isLoadingInitBPs = true;
              params.page = $stateParams.page ? parseInt($stateParams.page) : 1;
              $ctrl.showLoader = true;
              $ctrl.blueprintItemsToDisplay = [];
              getProcess(params).then(function (response) {
                if (response.meta.pagination.total > $ctrl.pager.total) {
                  $ctrl.pager = response.meta.pagination;
                }
                $ctrl.processes = angular.copy(response.data);
                var currentUserId = _.get(identity, 'id');
                if ($ctrl.processes.length > 0) {
                  _.forEach($ctrl.processes, function (process) {
                    if ($ctrl.isGuest) {
                      _.get(process, 'guest_watchers.data') ? _.set(process, 'current_guest_watchers', getCurrentUserWatcher(process.guest_watchers.data)) : angular.noop();
                    } else {
                      _.get(process, 'member_watchers.data') ? _.set(process, 'current_member_watchers', getCurrentUserWatcher(process.member_watchers.data)) : angular.noop();
                    }
                    $ctrl.starredByUsers = [];
                    _.forEach(_.get(process.tags, 'data', []), function(tag) {
                      _.assign(tag, { "title": tag.title.replace(/#/g, "") });
                    });
                    _.forEach(_.get(process, 'starredByUsers'), function(user) {
                      if(user && _.includes(_.map($ctrl.users, 'id'), user)) {
                        $ctrl.starredByUsers.push(_.find($ctrl.users, { 'id': user }));
                      }
                    });
                    var itemData = {
                      id: process.id,
                      type: 'process',
                      data: process,
                      statusDropDown: _.find($ctrl.statusOptions, { value: process.status }),
                      createdByUser: _.find($ctrl.users, { 'id': process.created_by }),
                      updatedByUser: _.find($ctrl.users, { 'id': process.last_updated_by }),
                      starredByUsers: $ctrl.starredByUsers,
                      meta: {
                        isFormType: _.get(process, 'type') ===  BLUEPRINT_TYPE.FORM,
                        isProcedureType: _.get(process, 'type') ===  BLUEPRINT_TYPE.PROCEDURE,
                        isDocumentType: _.get(process, 'type') ===  BLUEPRINT_TYPE.DOCUMENT
                      },
                      havePermissionToDuplicate: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_duplicate', []), _.get(process, 'permissions.data.checklist_duplicate_group', []), $ctrl.orgGroups), 
                      havePermissionToEdit: !$ctrl.isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_edit', []), _.get(process, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups),
                      havePermissionToRead: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_read', []), _.get(process, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups),
                      havePermissionToLaunch: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.process_launch', []), _.get(process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups)
                    };
                    $ctrl.blueprintItemsToDisplay.push(itemData);
                  });
                }
                $ctrl.selectedBlueprint = $stateParams.blueprint_id;
                $ctrl.pager.page++;
                $ctrl.showLoader = false;
                isPaginated($ctrl.pager);
                ProgressBar.stop();
                isScrollableList();
                $ctrl.isLoadingInitBPs = false;
                $timeout(function () {
                  document.getElementById('blueprint_list').scrollTop = 0;
                  $ctrl.folderName = $ctrl.statusFilter === null ? 'uncategorized' : ($ctrl.statusFilter !== 'folder' ? $ctrl.statusFilter : _.get($ctrl.breadcrumb, 'name'));
                }, 500);
              }, function () {
                $ctrl.showLoader = false;
                $ctrl.isLoadingInitBPs = false;
                ProgressBar.stop();
              });
          }

          function loadMore(page) {
            ProgressBar.start();
            $ctrl.selectedBlueprint = $stateParams.blueprint_id = null;
            $ctrl.pager.current_page = page;
            $state.go($state.current, { page: page }, { notify: false });
            $ctrl.search.searchText ? searchProcess(true) : getDataInPage($ctrl.pager);
          }

          function searchProcess(isPaginated) {
            if (!isPaginated) {
              $ctrl.pager.current_page = 1;
              $state.go($state.current, { page: 1 }, { notify: false });
            }
            var requestParams = {
              q: $ctrl.search.searchText
            };
            $ctrl.searchParams =  requestParams;
            angular.extend(requestParams, $ctrl.pager);
            getDataInPage(requestParams);
          }

          function getFolderList() {
            $ctrl.folderLoading = true;
            var param = {
              pagination: false,
              sort: 'title'
            };
            if ($stateParams.status === 'folder') {
              angular.extend( param, { parent_id: $stateParams.folder_id });
            }
            FolderService
              .getFolders({
                parent_id: $stateParams.folder_id,
                pagination: false,
                sort: 'title'
              }).then(function (res) {
                $ctrl.folderTree = res.data;
                $ctrl.folderLoading = false;
              }, function() {
                $ctrl.folderLoading = false;
              });
          }

          /**
            * @ngdoc method
            * @name startRun
            * @description Check plan is doc to alert updated
            */
          function startRun($event) {
            $event.stopPropagation();
            if (!$ctrl.haveAuthority())
              return;
            if ($ctrl.isDocsPlan) {
              return AuthPlan.setModalContent(AuthPlan.getCurrentPlanCode(), 'launchBP');
            }
          }

          /**
          * @ngdoc method
          * @name archiveProcess
          * @description archive a process
          * @param {*} event
          * @param {Object} process
          * @param {*} i (index)
          */
          function archiveProcess(event, process, i) {
            if (!$ctrl.haveAuthority())
              return;
            Growl.clearAllMessages('global');
            event.stopPropagation();
            $ctrl.isOpenPopOver[i] = false;
            $ctrl.isOpenPopOverFavorite[i] = false;
            archived({
              process: process
            }).then(function () { }, function () { });
          }

          /**
          * @ngdoc method
          * @name undoArchiveProcess
          * @description undo archive process
          * @param {*} params 
          */
          function undoArchiveProcess(params) {
            if (!params) {
              return;
            }
            if (!$ctrl.haveAuthority())
              return;
            Growl.clearAllMessages('global');
            $ctrl.blueprintItemsToDisplay.splice(params.index, 0, params.process);
            $timeout(function () {
              growl.success($filter('translate')('process.messages.unArchive'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, 500);
            unArchiveProcess(params.process.data, true);
          }

          /**
          * @ngdoc method
          * @name unArchiveProcess
          * @description un archive a process
          * @param {Object} process 
          * @param {Object} isUndo
          */
          function unArchiveProcess(process, isUndo) {
            if (!$ctrl.haveAuthority())
              return;
            if (!isUndo) {
              blockUI.start();
            }
            ProcessService.unArchiveProcess({
              id: process.id
            }).then(function (data) {
              if (!isUndo) {
                var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
                $ctrl.blueprintItemsToDisplay.splice(index, 1);
                growl.success($filter('translate')('process.messages.unArchive'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                blockUI.stop();
              }
            }, function (error) {
              growl.error(error.message);
              blockUI.stop();
            });
          }

          /**
          * @ngdoc method
          * @name updateParameters
          * @description get process when parameters update
          * @param {Object} params 
          */
          function updateParameters(params) {
            params = angular.extend($ctrl.pager, params);
            getDataInPage(params);
          }

          /**
           * @ngdoc method
           * @name clearSearchProcess
           * @description clear search filter and get default process
           * @param {Boolean} isSearch 
           */
          function clearSearchProcess(isSearch) {
            $ctrl.isSearch = isSearch;
            if ($ctrl.search.searchText.length) {
              $ctrl.search.searchText = null;
              $ctrl.searchProcess(false);
            }
          }

          /**
          * @ngdoc method
          * @name getPreferences
          * @description Get updated preference values
          * @returns {Object} preference
          */
          function getPreferences() {
            preferencesStore.getPreferences($stateParams.id || identity.id).then(function (preference) {
              var blueprintTypeSelected = _.find(preference, { slug: "bp_type_filter_config" });
              $ctrl.blueprintTypeSelected = !!blueprintTypeSelected && _.get(blueprintTypeSelected, 'metadata.id')
                ? _.get(blueprintTypeSelected, 'metadata')
                 : _.first($ctrl.blueprintType);
              var bpFilterConfig = _.find(preference, { slug: "bp_filter_config" });
              _.set(_.find($ctrl.blueprintType, { id: _.get($ctrl.blueprintTypeSelected, 'id') }), 'isSelected', true);
              $ctrl.showDetails = _.get(bpFilterConfig, 'value', 'yes');
              $state.go($state.current, angular.extend($stateParams, {
                sortByType: _.get($ctrl.blueprintTypeSelected, 'id'),
                sortBy: _.get(bpFilterConfig, 'metadata.active_view.value')
              }), { notify: false, location: 'replace' });
              $ctrl.isLibrarySplashDismissed = _.get(_.find(preference, { slug: "library_splash_viewed" }), 'value', 'no') === 'yes';
              $ctrl.userPreferences = preference;
            });
          }

          /**
           * @ngdoc method
           * @name savePreference
           * @description Save/update preference.
           * @param slug
           * @param value
           * @returns {void}
           */
          function savePreference(slug, value) {
            var prefData = !value ?  { slug: slug, value: "no", metadata: {} }
                : { slug: slug, value: "yes", metadata: value };
            AccountService.setUserPreference({
              id: $stateParams.id || identity.id,
              preference: prefData
            }).then(function (response) {
              preferencesStore.updatePreferences(response.data);
            }, function () {
            });
          }

          /**
           * @ngdoc method
           * @name changeMode
           * @description Moved to edit/read page
           * @param {Object} process
           * @param {String} mode
           */
          function changeMode(process, mode) {
            $state.go('process.edit', {
              slug: process.id,
              view: mode
            });
          }

          /**
           * @ngdoc method
           * @name getTags
           * @param {Object} process
           * @param {Boolean} isToolTip
           * @description get tags
           */
          function getTags(process, isToolTip) {
            return ProcessService.getTagsWithHash(_.get(process, 'tags.data', []), isToolTip, 30);
          }

          /**
          * @ngdoc method
          * @name openViewMoreModel
          * @param {Object} process
          * @description open view more modal for viewmore details of summary
          */
          function openViewMoreModel(process) {
            ProcessService.openViewMoreModal({ summary: process.summary }, 'blueprint-view-more modal-more');
          }

          /**
           * @ngdoc method
           * @name getFolders
           * 
           * @description get all folders
           * @param {*} params
           */
          function getFolders(params) {
            var defer = $q.defer(), folderParams = {};
            angular.extend(params, {
              page: $ctrl.pager.current_page,
              per_page: $ctrl.pager.per_page !== 10 ? 10 : $ctrl.pager.per_page
            });
            angular.extend(folderParams, params, { sort: 'position' });
            if ($ctrl.folderID) {
              folderParams.parent_id = $ctrl.folderID;
            }
            FolderService.getFolders(_.omit(folderParams, ['onPageChanged', 'current_page', 'total']))
              .then(function (res) {
                defer.resolve(res);
              }, function (error) {
                defer.reject(error);
              });
            return defer;
          }

          /**
           * @ngdoc method
           * @name getProcess
           * @param {*} params
           * 
           * @description
           * get process 
           */
          function getProcess(params) {
            var withStr, sortByType = _.toString($stateParams.sortByType) === 'all_blueprints' ? void 0 : _.get($stateParams, 'sortByType');
            angular.extend(params, {
              type: _.toLower(sortByType) || void 0,
              sort: $stateParams.sortBy || 'updated'
            });
            if ($ctrl.statusFilter === 'example') {
              params = _.omit(params, ['total']);
              if ($ctrl.isGuest) {
                withStr = 'steps,steps.captures,tags,topic_tags,industry_tags,guest_watchers.watcher';
              } else {
                withStr = 'steps,steps.captures,tags,topic_tags,industry_tags,member_watchers.watcher';
              }
              angular.extend(params, {
                industryTag: $stateParams.sortByIndustry || void 0,
                topicTag: $stateParams.sortByTopic || void 0,
                with: withStr
              });
              if (_.get($rootScope.identity, 'guest.email')) {
                angular.extend(params, {
                  action: 'organizations',
                  action_id: _.get($rootScope.identity, 'guest.organization.id'),
                  featured: false
                });
              } else {
                angular.extend(params, { featured: true });
              }
              return PublicLibraryService.getPublicLibrary(params);
            } else if ($ctrl.isGuest && $ctrl.statusFilter === 'uncategorized') {
                angular.extend(params, {
                  org: _.get($rootScope.identity, 'guest.organization.id'),
                  guest_code: $stateParams.guest_code,
                  with: 'steps'
                });
                return PublicLibraryService.getPublicLibraryList(params);
            } else {
              params = _.omit(params, ['total']);
              if ($ctrl.isGuest) {
                withStr = 'tags,folder,permissions,starredByUsers,guest_watchers.watcher';
              } else {
                withStr = 'tags,folder,permissions,starredByUsers,member_watchers.watcher';
              }
              angular.extend(params, {
                append: 'steps_count',
                with: withStr,
                entire_folder_tree: "1"
              });
              if ($stateParams.status !== 'archived') {
                if ($stateParams.status === 'favorite') {
                  params.starred = 1;
                } else {
                  if(_.get($ctrl.searchParams, 'q')) {
                    params.q = _.get($ctrl.searchParams, 'q');
                  } else {
                    params.folder = $stateParams.folder_id || 0;
                  }
                }
              }
              if ($stateParams.status === 'archived') {
                params.archived = 'only';
              }
              return ProcessService.filter(params, true);
            }
          }
          
          function onSortChange(sort) {
            if (!!sort && _.get($rootScope.identity, 'default_organization.id')) {
              savePreference('bp_filter_config', { active_view: sort });
            }
            $state.transitionTo($state.current, angular.extend($stateParams, {
              sortBy: _.get(sort, 'value', '')
            }), { notify: false });
            fetchBluePrintByFilter($stateParams.status);
          }

          /**
           * @ngdoc method
           * @name getFavoriteCount
           * 
           * @description
           * getting Favorite count to display
           */
          function getFavoriteCount() {
            var params = {
                per_page: 1,
                current_page: 1,
                page: 1,
                append: "steps_count",
                with: "tags,folder,permissions",
                starred: 1
            };
            isFetchingFavoriteCount = true;
            ProcessService.filter(params, true).then(function (response) {
              if (response.meta.pagination.total > -1) {
                $ctrl.favoriteCount = response.meta.pagination.total;
              }
              isFetchingFavoriteCount = false;
              $ctrl.isFavoriteFetched = true;
            }, function () {
              $ctrl.isFavoriteFetched = false;
              isFetchingFavoriteCount = false;
            });
          }

          /**
           * @ngdoc method 
           * @name onNewFolderClick
           * @param {integer} folderId
           * @description
           * click handler on new folder click
           */
          function onNewFolderClick(folderId) {
            if (!$ctrl.haveAuthority())
              return;
            var createModal = FolderService.openFolderModal(null, folderId || 'root', 'checklist');
            createModal.result.then(onNewFolderAdded);
          }

          /**
           * @ngdoc method
           * @name onNewFolderAdded
           * @param {*} response 
           * 
           * @description
           * callback handler after folder created on modal popup
           */
          function onNewFolderAdded(response) {
            $rootScope.$emit('FOLDER:ADD_NEW', { folder: response.data });
          }

          /**
           * @ngdoc method
           * @name onNewFolderForBlueprint
           * @param {*} process 
           * 
           * @description
           * on new folder for blueprint added / directly added blueprint into a folder after created
           */
          function onNewFolderForBlueprint(process) {
            if (!$ctrl.haveAuthority()) {
              return;
            }
            var createModal = FolderService.openFolderModal(null, null, 'checklist');
            createModal.result.then(function (response) {
              var newFolder = response.data;
              process.folder_id = newFolder.id;
              ProcessService.update({
                id: process.id
              }, process).then(function (res) {
                newFolder.total = 0;
                if (!$ctrl.folderID) {
                  $ctrl.folders.splice(0, 0, newFolder);
                  $ctrl.foldersAsMenu.splice(0, 0, newFolder);
                }
                var folderExist = _.find($ctrl.folderItemsToDisplay, { type: 'folder' });
                if ((folderExist || $ctrl.pager.current_page === 1) && !$ctrl.folderID) {
                  $ctrl.folderItemsToDisplay.splice(0, 0, {
                    id: newFolder.id,
                    type: 'folder',
                    data: newFolder
                  });
                }
                _.remove($ctrl.blueprintItemsToDisplay, { id: process.id });
                var folderIndex = _.findIndex($ctrl.folders, { id: newFolder.id });
                if (folderIndex >= 0) {
                  $ctrl.folderID ? $ctrl.folders[folderIndex].total-- : $ctrl.folders[folderIndex].total++;
                }
                if ($ctrl.folderID) {
                  growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                } else {
                  growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                    bpName: res.data.title
                  }), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }
              });
            });
          }

          /**
           * @ngdoc method
           * @name onMoveToFolder
           * @param {*} process 
           * @param {*} folder 
           * 
           * @description
           * move blueprint to a folder
           */
          function onMoveToFolder(process, folder) {
            if (!$ctrl.haveAuthority())
              return;
            var processIndex = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
            if (processIndex > -1) {
              $ctrl.isOpenPopOver[processIndex] = false;
            }
            if (_.get(folder, 'id') !== _.get(process, 'folder.data.id')) {
              if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
                $ctrl.resetSelectedBP();
              }
              blockUI.start();
              process.folder_id = folder.id;
              ProcessService.update({
                id: process.id,
                with: 'folder',
                skipNotFound: true
              }, process).then(function(res) {
                blockUI.stop();
                postMoveToFolder(res);
              }, function(error) {
                blockUI.stop();
                if (error.status === 404) {
                  growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                  $state.go('process.templates', {
                    org_id: _.get($rootScope.identity, 'default_organization.id')
                  });
                }
              });
            } else {
              Growl.clearAllMessages('global');
              $timeout(function () {
                growl.warning($filter('translate')('runs.messages.folder.alreadyOnFolder', {
                  bpName: _.get(process, 'title'),
                  folderName: _.get(folder, 'name')
                }), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
              }, 0);
            }
          }

          function onFolderChange(item) {
            $ctrl.isOpenPopOver = {};
            var bplist_elem = document.getElementById('blueprint_list');
            if (bplist_elem) {
              bplist_elem.scrollTop = 0;
            }
            $ctrl.statusFilter = 'folder';
            $state.transitionTo($state.current, angular.extend($stateParams, {
              status: 'folder',
              folder_id: item ? item.id : void 0,
              blueprint_id: null,
              page: 1
            }), {
              notify: false
            });
            fetchBluePrintByFilter('folder');
            getAllParentData(item ? item.id : void 0);
           }

          function getAllParentData(folderId) {
            if (!folderId) {
              return;
            }
            var params = { id: folderId, with: 'parent' };
            $ctrl.breadcrumb = null;
            FolderService.getFolders(params).then(function (res) {
              $ctrl.breadcrumb = res.data;
              if ($ctrl.breadcrumb && $ctrl.breadcrumb.parent && $ctrl.breadcrumb.parent.data) {
                var totalLevels = prepareBreadcrumbData($ctrl.breadcrumb, 1);
                angular.extend($ctrl.breadcrumb, { totalLevels: totalLevels });
              }
            }, function () {
              $ctrl.breadcrumbLoading = false;
            });
          }

          function prepareBreadcrumbData (breadcrumb, level) {
            angular.extend(breadcrumb, { level: level });
            if (breadcrumb.parent) {
              level++;
              return prepareBreadcrumbData(breadcrumb.parent.data, level);
            }
            return level;
          }

          function onMenuItem($event, menu) {
            $ctrl.isOpenPopOver = {};
            $event.stopPropagation();
            //delesect treeview
            if ($("#ty-kendo-treeview").data("kendoTreeView")) {
              $("#ty-kendo-treeview").data("kendoTreeView").select($());
            }
            if ($stateParams.status !== menu) {
                if ($ctrl.isSearch) {
                  $ctrl.clearSearchProcess(false);
                }
                $ctrl.folderId = null;
                if ($ctrl.statusFilter !== 'archived' && $ctrl.statusFilter !== 'home') {
                  document.getElementById('blueprint_list').scrollTop = 0;
                }
                $ctrl.isLeftPaneBlueprintShow = false;
                $ctrl.statusFilter = menu;
                $state.transitionTo($state.current, angular.extend($stateParams, {
                  folder_id: null,
                  blueprint_id: null,
                  status: menu,
                  sortByType: menu === 'example' && $ctrl.blueprintTypeSelected? _.get($ctrl.blueprintTypeSelected, 'id') : _.get($stateParams, 'sortByType', null),
                  sortByIndustry: menu === 'example' && $ctrl.industryTagsSelected ? $ctrl.industryTagsSelected : null,
                  sortByTopic: menu === 'example' && $ctrl.topicTagsSelected? $ctrl.topicTagsSelected : null,
                  page: menu !== 'home' ? 1 : null
                }), {
                  notify: false
                });
                fetchBluePrintByFilter(menu);
            }
          }

          function onFilterBlueprintByTag(tag) {
            $ctrl.statusFilter = 'tag';
            $state.transitionTo($state.current, angular.extend($stateParams, {
              folder_id: null,
              blueprint_id: null,
              status: 'tag',
              page: 1,
              tags: _.get(tag, 'id')
            }), {
              notify: false
            });
            $ctrl.filterTags = _.get(tag, 'id');
            fetchBluePrintByFilter('home');
          }

          function fetchBluePrintByFilter(menu) {
            $ctrl.statusFilter = menu;
            $ctrl.processType = $ctrl.statusFilter === 'archived' ? $ctrl.statusFilter : angular.noop();
            $ctrl.blueprintItemsToDisplay = [];
            $ctrl.templateUrl = 'app/modules/process/components/library/'
              + ($ctrl.statusFilter === 'archived' ? 'archive-process-library.html' 
                : $ctrl.statusFilter === 'home' ? 'blueprint-activity.html'
                : 'all-process.html');
            $ctrl.pager = ProcessService.getPager({
              current_page: $stateParams.page ? parseInt($stateParams.page) : 1,
              per_page: 20
            });
            getDataInPage($ctrl.pager);
          }

          function folderClick(folder) {            
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: folder });
          }

          folderSelectedWatcher = $rootScope.$on('FOLDER:CLICK', function (e, data) {
            $ctrl.onMoveToFolder(data.blueprint, data.folder);
          });

          onFolderClickWatcher = $rootScope.$on('FOLDER_CLICK:VIEW_BLUEPRINT', function (e, data) {
            $ctrl.folderId = _.get(data, 'folder.id');
            if(_.get(data, 'folder.id') && (_.get(data, 'folder.id') !== _.get($stateParams, 'folder_id'))) {
              if ($ctrl.showLoader) {
                return;
              }
              if ($ctrl.folderId === 'uncategorized') {
                onMenuItem(e, $ctrl.folderId);
              } else {
                if ($ctrl.isSearch) {
                  $ctrl.clearSearchProcess(false);
                }
                $ctrl.isLeftPaneBlueprintShow = false;
                onFolderChange(_.get(data, 'folder'));
                getFolderList();
              }
            }
          });

          /**
           * @ngdoc method
           * @name postMoveToFolder
           * @param {*} res 
           * 
           * @description
           * callback after blueprint moved to folder
           */
          function postMoveToFolder(res) {
            var folderIndex = _.findIndex($ctrl.folders, { id: res.data.folder_id }),
             blueprintIndex = _.findIndex($ctrl.blueprintItemsToDisplay, { id: res.data.id });
            folderIndex >= 0 ? $ctrl.folders[folderIndex].total++
              : angular.noop();
            if ($stateParams.status === 'favorite') {
              angular.extend($ctrl.blueprintItemsToDisplay[blueprintIndex].data, {
                folders: _.get(res, 'data.folders'), folder: _.get(res, 'data.folder')
              });
            } else {
              _.remove($ctrl.blueprintItemsToDisplay, {
                id: res.data.id
              });
            }
            if ($ctrl.folderID) {
              growl.success($filter('translate')('runs.messages.folder.movedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            } else {
              growl.success($filter('translate')('runs.messages.folder.addedToFolder', {
                bpName: res.data.title
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }
          }

          function onRemoveFromFolder(process) {
            if (!$ctrl.haveAuthority())
              return;
            var processIndex = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
            if (processIndex > -1) {
              $ctrl.isOpenPopOver[processIndex] = false;
            }
            blockUI.start();
            process.folder_id = null;
            if (_.get($ctrl.selectedBlueprint, 'id') === process.id) {
              $ctrl.resetSelectedBP();
            }
            ProcessService.update({
              id: process.id
            }, process).then(function(res) {
              ProcessService.setPinned({
                id: _.get(res, "data.id"),
                is_pinned: false
              }).then(function () { }, function () {});
              blockUI.stop();
              postRemoveFromFolder(res);
            });
          }

          function postRemoveFromFolder(res) {
            _.remove($ctrl.blueprintItemsToDisplay, { id: res.data.id });
            growl.success($filter('translate')('runs.messages.folder.removedFromFolder', {
              bpName: res.data.title
            }), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdoc method
           * @name openRequestModal
           * @description
           * open request modal
           */
          function openRequestModal() {
            $uibModal.open({
              component: 'editTask',
              windowClass: 'task-modal create-task-modal',
              resolve: {
                isRequestBp: function () {
                  return true;
                }
              }
            });
          }

          /**
           * @ngdoc method
           * @name permanentlyDeleteBP
           * @description
           * Permanently delete BP
           * @param {*} process
           */
          function permanentlyDeleteBP(process) {
            blockUI.start();
            ProcessService.permanentlyDeleteBP({
              id: process.id
            }).then(function () {
              blockUI.stop();
              var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id });
              $ctrl.blueprintItemsToDisplay.splice(index, 1);
              growl.success($filter('translate')('template.permanentlyDeleteComponent.successMessage', {
                componentName: 'template'
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });

              $timeout(function () {
                Growl.clearAllMessages('global');
              }, 5000);
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name openConfirmBPDeletion
           * @description
           * open BP deletion confirmation modal
           * @param {*} process
           */
          function openConfirmBPDeletion(process) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            Growl.clearAllMessages('global');
            $confirm({
              'header': $filter('translate')('template.permanentlyDeleteComponent.header', {
                componentName: 'template'
              }),
              'body': $filter('translate')('template.permanentlyDeleteComponent.body', {
                entityName: process.title,
                componentName: 'Template'
              }),
              'buttons': {
                'accept': $filter('translate')('template.permanentlyDeleteComponent.deleteEntity', {
                  componentName: 'template'
                }),
                'cancel': 'global.confirmAction.buttons.close'
              },
              modalType: 'modal-danger'
            }).then(function () {
              permanentlyDeleteBP(process);
            }, function () {
              $log.info('Delete BP is cancelled');
            });
          }

          function onFavoriteClick(blueprint, $event) {
            $event.stopPropagation();
            var canReadBlueprint = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, _.get(identity, 'id'), _.get(blueprint, 'permissions.data.checklist_read', []), _.get(blueprint, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups);
            Growl.clearAllMessages('global');
            blockUI.start();
            if (canReadBlueprint) {
                blueprint.starred = !blueprint.starred;
                var resource = [];
                if (blueprint.starred) {
                  resource = [
                    ProcessService.setFavorite(_.toString(_.get(blueprint, 'id'))),
                    FavoriteService.addFavorite({
                      userId: _.get($rootScope, 'identity.id'),
                      objId: _.get(blueprint, 'id'),
                      objectType: 'checklist'
                    })
                  ];
                } else if(getCurrentUserWatcher(_.get(blueprint, 'member_watchers.data')).length>0) {
                  var watcherData = _.first(getCurrentUserWatcher(_.get(blueprint, 'member_watchers.data')));
                  resource = [
                    ProcessService.removeFavorite(_.toString(_.get(blueprint, 'id'))),
                    FavoriteService.removeFavorite(_.get(watcherData, 'id'))
                  ];
                } else {
                  resource = [
                    ProcessService.removeFavorite(_.toString(_.get(blueprint, 'id')))
                  ];
                }
                $q.all(resource).then(function () {
                  $timeout(function () {
                    var translateMsg = blueprint.starred ? 'runs.messages.favorited' : 'runs.messages.unFavorited';
                    growl.success($filter('translate')(translateMsg), {
                      referenceId: 'global',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                  }, 250);
                  if (blueprint.starred) {
                    ProcessService.get({
                       id: _.get(blueprint, 'id'),
                       with: 'member_watchers.watcher',
                       skipNotFound: true
                    }).then(function (res) {
                      if(_.get(res, 'data.member_watchers.data').length > 0) {
                        _.set(blueprint, 'member_watchers', res.data.member_watchers);
                        _.set(blueprint, 'current_member_watchers', getCurrentUserWatcher(res.data.member_watchers.data));
                      }
                      blockUI.stop();
                    });
                  } else {
                    var anotherUserWatcher = getAnotherUserWatcher(_.get(blueprint, 'member_watchers.data'));
                    _.set(blueprint, 'member_watchers.data', anotherUserWatcher);
                    _.set(blueprint, 'current_member_watchers', []);
                    blockUI.stop();
                  }
                  getFavoriteCount();
                  $rootScope.$emit('PROCESS:FAVORITE_PROCESS');
                  if (!blueprint.starred && $ctrl.statusFilter === 'favorite') {
                    $rootScope.$emit('BLUEPRINT:REMOVE_BLUEPRINT', { blueprint_id: blueprint.id }); 
                  }
                }, function () {
                  blueprint.starred = !blueprint.starred;
                });
            } else {
                $timeout(function () {
                  growl.warning($filter('translate')('process.messages.favoriteRestricted'), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, 250);  
            }
          }

          function onRemovedFromFavorite(blueprint) {
            var totalFolder = _.filter($ctrl.blueprintItemsToDisplay, { type: 'folder' }),
              currentUserId = _.get(identity, 'id');
            $ctrl.blueprintItemsToDisplay.splice(totalFolder ? (totalFolder.length + 1) : 0, 0, {
              id: blueprint.id,
              data: blueprint,
              type: 'process',
              meta: {
                isFormType: _.get(blueprint, 'type') ===  BLUEPRINT_TYPE.FORM,
                isProcedureType: _.get(blueprint, 'type') ===  BLUEPRINT_TYPE.PROCEDURE,
                isDocumentType: _.get(blueprint, 'type') ===  BLUEPRINT_TYPE.DOCUMENT
              },
              havePermissionToDuplicate: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, currentUserId, _.get(blueprint, 'permissions.data.checklist_duplicate', []), _.get(blueprint, 'permissions.data.checklist_duplicate_group', []), $ctrl.orgGroups),
              havePermissionToEdit: !$ctrl.isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, currentUserId, _.get(blueprint, 'permissions.data.checklist_edit', []), _.get(blueprint, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups),
              havePermissionToRead: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, currentUserId, _.get(blueprint, 'permissions.data.checklist_read', []), _.get(blueprint, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups),
              havePermissionToLaunch: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, blueprint.created_by, currentUserId, _.get(blueprint, 'permissions.data.process_launch', []), _.get(blueprint, 'permissions.data.process_launch_group', []), $ctrl.orgGroups)
            });
          }

          function onLibrarySplashDismissed(e) {
            e.preventDefault();
            $timeout(function () {
              $ctrl.isLibrarySplashDismissed = true;
              AccountService.setUserPreference({
                slug: "library_splash_viewed",
                value: "yes"
              }).then(function (response) {
                preferencesStore.updatePreferences(response.data);
              }, function (error) {
                $log.error(error);
              });
            }, 750);
          }
 
          /**
            * @name shareBPLink
            *
            * @description
            * Share BP link
            */
          function shareBPLink() {
            $uibModal.open({
              component: 'shareLink',
              windowClass: 'modal-fade fade share-link-modal',
              resolve: {
                links: function () {
                  var dataLinks = [],
                  params = [];
                  dataLinks.push({
                    'title': $filter('translate')('process.label.share_folder_label'),
                    'link': ENV_CONFIG.TYFY_MAIN_URL + 
                         $state.href('process.templates', angular.extend(params, $stateParams, { blueprint_id: null }))
                  });
                  if (_.get($stateParams, 'blueprint_id')) {
                    params = []; 
                    dataLinks.push({
                      'title': $filter('translate')('process.label.share_blueprint_label'),
                      'link': ENV_CONFIG.TYFY_MAIN_URL + 
                          $state.href('process.templates', angular.extend(params, { blueprint_id: $stateParams.blueprint_id}))
                    });
                  }
                  return dataLinks;
                },
                titleTranslation: function () {
                  return 'process.label.share_link_title';
                }
              }
            });
          }

          function onViewBluePrint(selected, index) {
            var page = Math.ceil((parseInt(index)+1)/20);
            body.addClass('library-view-modal');
            $state.transitionTo($state.current.name,
              angular.extend($stateParams, {
                page: page,
                blueprint_id: _.get(selected, 'id'),
                public_org: _.get(selected, 'organization_id')
              }), { notify: false });
            $ctrl.selectedBlueprint = angular.copy(selected);
            $analytics.eventTrack('Click', { blueprint_id: _.get(selected, 'id'), blueprint_name: _.get(selected, 'title') });
          }

          function resetSelectedBP() {
            $state.transitionTo($state.current, angular.extend($stateParams, {
              blueprint_id: null
            }), {
              notify: false 
            });
            body.removeClass('library-view-modal');
            $ctrl.selectedBlueprint = null;
          }

          function showMoreTag() {
            var page = angular.extend($ctrl.pagination, { page: $ctrl.pagination.page + 1 });
            getAllTags(page);
          }

          function getAllTags() {
            $ctrl.tagLoading = true;
            if (!$ctrl.lastPage) {
              TagsService.get({
                action: 'tags',
                sort: 'title',
                page: $ctrl.pagination.page,
                per_page: $ctrl.pagination.per_page,
                with: 'statistics'
              }).then(function (response) {
                blockUI.stop();
                $ctrl.tagMeta = _.get(response, 'meta.pagination');
                $ctrl.lastPage = _.get(response, 'meta.pagination.total_pages') <= $ctrl.pagination.page;
                var tagData = _.filter(response.data, function(tag) {
                  return !(_.startsWith(tag.title, 'task:') || _.startsWith(tag.title, 'from:') || _.startsWith(tag.title, 'step:'));
                });
                $ctrl.tags = _.concat($ctrl.tags, tagData);
                if (!$ctrl.lastPage && tagData.length < 2) {
                  showMoreTag();
                }
              }, function () {
                blockUI.stop();
                $ctrl.tagLoading = false;
              });
            }
          }

          function isPaginated(pager) {
            $ctrl.pageinated = Math.ceil(pager.total/pager.per_page) > 1;
          }

          function launchProcess() {
            body.removeClass('library-view-modal');
            if (!$ctrl.haveAuthority())
              return;
            if ($ctrl.isDocsPlan) {
              return AuthPlan.setModalContent(AuthPlan.getCurrentPlanCode(), 'launchBP');
            }
            $state.go('run.create', { process_id: _.get($ctrl.selectedBlueprint, 'id') });
          }

          function onPageFetch() {
            if ($ctrl.isLoadingInitBPs || $ctrl.isBusy) {
              return;
            }
            if ($ctrl.pager.current_page < $ctrl.pager.total_pages) {
              $ctrl.isBusy = true;
              $ctrl.pager = ProcessService.getPager({
                page: parseInt(_.get($ctrl.pager, 'current_page', 1))+1,
                current_page: parseInt(_.get($ctrl.pager, 'current_page', 1))+1,
                per_page: 20
              });
              getProcess($ctrl.pager).then(function (response) {
                if (response.meta.pagination.total > $ctrl.pager.total) {
                  $ctrl.pager = response.meta.pagination;
                }
                $ctrl.processes = response.data;
                if ($ctrl.processes.length > 1) {
                    var currentUserId = _.get(identity, 'id');
                    _.forEach($ctrl.processes, function (process) {
                      $ctrl.starredByUsers = [];
                      _.forEach(_.get(process, 'starredByUsers'), function(user) {
                        if(user) {
                          $ctrl.starredByUsers.push(_.find($ctrl.users, { 'id': user }));
                        }
                      });
                      var itemData = {
                        id: process.id,
                        type: 'process',
                        data: process,
                        statusDropDown: _.find($ctrl.statusOptions, { value: process.status }),
                        createdByUser: _.find($ctrl.users, { 'id': process.created_by }),
                        updatedByUser: _.find($ctrl.users, { 'id': process.last_updated_by }),
                        starredByUsers: $ctrl.starredByUsers,
                        meta: {
                          isFormType: _.get(process, 'type') ===  BLUEPRINT_TYPE.FORM,
                          isProcedureType: _.get(process, 'type') ===  BLUEPRINT_TYPE.PROCEDURE,
                          isDocumentType: _.get(process, 'type') ===  BLUEPRINT_TYPE.DOCUMENT
                        },
                        havePermissionToDuplicate: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_duplicate', []), _.get(process, 'permissions.data.checklist_duplicate_group', []), $ctrl.orgGroups),
                        havePermissionToEdit: !$ctrl.isLightMember && UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_edit', []), _.get(process, 'permissions.data.checklist_edit_group', []), $ctrl.orgGroups),
                        havePermissionToRead: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.checklist_read', []), _.get(process, 'permissions.data.checklist_read_group', []), $ctrl.orgGroups),
                        havePermissionToLaunch: UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, currentUserId, _.get(process, 'permissions.data.process_launch', []), _.get(process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups)
                      };
                      $ctrl.blueprintItemsToDisplay.push(itemData);
                    });
                }
                $ctrl.pager.page++;
                $ctrl.isBusy = false;
                isPaginated($ctrl.pager);
                ProgressBar.stop();
              }, function () {
                $ctrl.isBusy = false;
                ProgressBar.stop();
              });
            }
          }

          paneIsScrollEventHandler = $rootScope.$on('LEFT_PANE:SCROLL_DOWN', function () {
            isScrollableLeftPane();
          });

          function scrollBottom(elementById) {
            if ($window.innerWidth < 1280) {
              elementById = 'blueprint_left_pane'; //For mobile view
            }
            document.getElementById(elementById).scrollBy(0, 700);
          }

          /**
          * @ngdoc method
          * @name getUsers
          * @description Get all the orgnanization users(active/invited/disable)
          * @returns {object} users
          */
          function getUsers() {
            store.getUsers().then(function (response) {
              $ctrl.users = UsersService.getActiveUsers(response, false);
              $ctrl.canCreateOrEditTemplates = UtilsService.canCreateOrEditTemplates(response);
            });
            var light_role_error_snippet = _.get($rootScope, 'identity.default_organization.light_role_error_snippet');
            if (light_role_error_snippet) {
              OrganizationsService.getTextTemplate(_.get($rootScope, 'identity.default_organization.light_role_error_snippet'), true, $rootScope.userState)
                .then(function (res) {
                  $ctrl.lightRoleSnippet = _.get(res, 'data', '');
                });
            } else {
              $ctrl.lightRoleSnippet = false;
            }
          }

          /**
          * @ngdoc method
          * @name onPlayClick
          * @description Play Blueprint Explanation Video
          * @param {*} data 
          * @returns {object} video
          */
          function onPlayClick(data) {
            $uibModal.open({
              component: 'explanationVideoModal',
              windowClass: 'explanation-video-modal',
              resolve: {
                data: function () {
                  return data;
                }
              }
            });
          }

          /**
          * @ngdoc method
          * @name archived
          * @description archive a process
          * @param {Object} process
          */
          function archived(process) {
           var defer = $q.defer();
            ProcessService.archiveProcess({
              id: process.id || process.process.id
            }).then(function (res) {
             var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: process.id || process.process.id });
              $ctrl.blueprintItemsToDisplay.splice(index, 1);
              growl.success($filter('translate')('process.messages.archive'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: undoArchiveProcess,
                  param: {
                    "process": process.process || process,
                    "index": index
                  },
                  linkText: $filter('translate')('runs.dashboard.undo')
                }
              });
              $log.info("Blueprint archived successfully");
              $ctrl.resetSelectedBP();
              defer.resolve();
            }, function () {
              defer.reject();
            });
            return defer.promise;
          }

          /**
          * @ngdoc method
          * @name onArchiveProcess
          * @description archive a process on modal
          */
          function onArchiveProcess() {
            var blueprint = _.find($ctrl.blueprintItemsToDisplay, { id: $ctrl.selectedBlueprint.id || $ctrl.selectedBlueprint });
            archived(blueprint).then(function() { });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar 
           * @public
           * @description set default avatar
           * @param {string} avatar 
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText 
           * @public
           * @description set default avatar Text
           * @param {string} firstname 
           * @param {string} lastname 
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
          * @ngdoc method
          * @name isViewFavorite
          * @description View favorite user list  in process
          * @param {Object} starredByUsers
          */
          function isViewFavorite(process, $event, i) {
            $event.stopPropagation();
            if ($ctrl.isOpenPopOverFavorite[i]) {
              $ctrl.isOpenPopOverFavorite = [];
              return;
            }
            $ctrl.isOpenPopOver = [];
            $ctrl.isOpenPopOverFavorite = [];
            $timeout(function () {
              $ctrl.starredByUsers = [];
              _.forEach(_.get(process, 'starredByUsers'), function(userID) {
                if (userID) {
                  var findUserObj = _.find($ctrl.users, { 'id': userID });
                  if (findUserObj) {
                    $ctrl.starredByUsers.push(findUserObj);
                  }
                }
              });
              $ctrl.isOpenPopOverFavorite[i] = true;
            }, 200);
          }

          /**
          * @ngdoc method
          * @name searchFavoriteUsers
          * @description Search favorite user list  in process
          * @param {Object} starredByUsers
          */
          function searchFavoriteUsers(event, id) {
            var blueprint = _.find($ctrl.blueprintItemsToDisplay, { id: id });
            $ctrl.starredByUsers = _.filter(blueprint.starredByUsers, function (value) {
              return _.toLower(value.full_name).indexOf(_.toLower(event.currentTarget.value)) !== -1;
            });
          }

          /**
          * @ngdoc method
          * @name onPinned
          * @description Pin on blueprint
          */
          function onPinned(blueprint, i) {
            Growl.clearAllMessages('global');
            $ctrl.isOpenPopOver[i] = false;
            blueprint.is_pinned = !blueprint.is_pinned;
            $ctrl.showLoader = true;
            ProcessService.setPinned({
              id: blueprint.id,
              is_pinned: blueprint.is_pinned
            }).then(function () {
                getDataInPage($ctrl.pager);
                var translateMsg = blueprint.is_pinned ? 'runs.messages.pinned' : 'runs.messages.unPinned';
                growl.success($filter('translate')(translateMsg), {
                  referenceId: 'global',
                  disableIcons: true,
                  disableCloseButton: true
                });
            }, function () {
              blueprint.is_pinned = !blueprint.is_pinned;
              $ctrl.showLoader = false;
            });
          }

          //Toggle blueprint left pane in mobile view
          function toggleLeftPaneBlueprintShow () {
            var scrollElement = document.getElementById('leftPaneScroll');
            $ctrl.isLeftPaneBlueprintShow = !$ctrl.isLeftPaneBlueprintShow;
            scrollElement.style.display = $ctrl.isLeftPaneBlueprintShow ? 'block' : 'none';
          }

          //Check blueprint permission
          function checkBlueprintPermission (item) {
            var checkPermission = false;
            if ($ctrl.isGuest || (item.data.organization_id !== $ctrl.organizationId && $ctrl.stateParams.status === 'example')) {
              return checkPermission;
            }
            var hasPopOverValues = _.some($ctrl.isOpenPopOver, function (value) { return value; }),
            hasFavoritePopOverValues = _.some($ctrl.isOpenPopOverFavorite, function (value) { return value; });
            if (!item.havePermissionToRead && !item.havePermissionToEdit) {
              return checkPermission;
            }
            if (hasPopOverValues || hasFavoritePopOverValues) {
              return checkPermission;
            }
            checkPermission = true;
            return checkPermission;
          }

          //Handle breadcrumb click
          function onBreadcrumbClick (e, folder) {
            if (e) {
              e.stopPropagation();
            }
            $state.go('process.templates', {
              status: 'folder',
              page: 1,
              folder_id: folder.id
            });
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: folder });
          }

          function onLightRoleErrorMessage() {
            $uibModal.open({
              component: 'lightRoleErrorMessageModal',
              windowClass: $ctrl.lightRoleSnippet ? 'light-role-error-message-modal': 'light-role-error-message-modal no-snippet',
              resolve: {
                data: function () {
                  return $ctrl.lightRoleSnippet;
                }
              }
            });
          }

          //Toggle edit popover
          function toggleEditPopover (e) {
            e.stopPropagation();
            $ctrl.isOpenPopOver = [];
            $ctrl.isOpenPopOverFavorite = [];
          }

          folderbroadUpdateHandler = $rootScope.$on('FOLDER:UPDATE_BREADCRUMB', function () {
            $stateParams.folder_id ? getAllParentData($stateParams.folder_id) : angular.noop();
            $ctrl.refreshFolderTreeView = true;
            $timeout(function () { $ctrl.refreshFolderTreeView = false; }, 0);
          });

          blueprintRemoveListHandler = $rootScope.$on('BLUEPRINT:REMOVE_BLUEPRINT', function (e, data) {
            var index = _.findIndex($ctrl.blueprintItemsToDisplay, { id: data.blueprint_id });
            $ctrl.blueprintItemsToDisplay.splice(index, 1);
          });

          blueprintUpdatedFavoriteHandler = $rootScope.$on('BLUEPRINT:UPDATED_FAVORITE_BLUEPRINT', function (e, data) {
            var blueprint = _.find($ctrl.blueprintItemsToDisplay, { id: data.blueprint_id });
            onFavoriteClick(blueprint.data);
          });

          blueprintFavoriteRemovedHandler = $rootScope.$on('BLUEPRINT:UNFAVORITE_BLUEPRINT', function () {
            getDataInPage($ctrl.pager);
            getFavoriteCount();
          });

          addNewFolderClickEventHandler = $rootScope.$on('FOLDER:ADD_NEW', function(event, data) {
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: _.get(data, 'folder') });
            getFolderList();
          });

          //controller ends
        }
    });
})();
